import { useCallback, useEffect, useMemo, useState } from 'react'

import Board from '@asseinfo/react-kanban'
import { Box, Button, LinearProgress, Tooltip, useTheme } from '@mui/material'
import { RiAddLine } from '@remixicon/react'
import { debounce } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { useMutation } from '@redwoodjs/web'

import { logger } from 'src/lib/logger'

import { shouldShowOpp } from '../Pipeline'
import PipelineBoardCard from '../PipelineBoardCard/PipelineBoardCard'
import PipelineBoardColumnHeader from '../PipelineBoardColumnHeader/PipelineBoardColumnHeader'

import { getBoardStyles } from './boardStyles'
const CREATE_STAGE_FROM_BOARD = gql`
  mutation boardCreateStage($input: StageCreateInput!) {
    createStage(input: $input) {
      id
      title
      workspaceId
      pipelineId
      position
      entranceCriteria
      likelihoodToClose
    }
  }
`

// gql for UPDATE_STAGE_FROM_BOARD:
const UPDATE_STAGE_FROM_BOARD = gql`
  mutation boardUpdateStage($input: StageUpdateInput!) {
    updateStage(input: $input) {
      id
      title
      workspaceId
      pipelineId
      position
      entranceCriteria
      likelihoodToClose
    }
  }
`

const UPDATE_OPPORTUNITY_FROM_BOARD = gql`
  mutation pipelineUpdateOpportunity($input: OpportunityUpdateInput!) {
    updateOpportunity(input: $input) {
      id
    }
  }
`

const ColumnAdder = ({ addColumn }) => {
  return (
    <Tooltip
      title="Add a new stage"
      placement="top"
      arrow={true}
    >
      <Button
        sx={{
          transition: 'all 0.4s ease',
          borderRadius: '0px !important',
          height: '46px',
          width: '272px',
          flexShrink: 0,
          color: (theme) => theme.palette.action.disabled,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          fontWeight: 500,
          '& .remixicon': {
            transition: 'all 0.22s ease',
            color: (theme) => theme.palette.action.disabled,
          },
          '&:hover': {
            color: (theme) => theme.palette.primary.main,
            '& .remixicon': {
              color: (theme) => theme.palette.primary.main,
            },
            borderRadius: '6px !important',
            border: 'none',
          },
        }}
        onClick={() =>
          addColumn({
            id: uuidv4(),
            title: 'New stage',
            cards: [],
            entranceCriteria: [],
          })
        }
      >
        <RiAddLine
          style={{ width: '24px', height: '24px', marginRight: '8px' }}
        />
        Add stage
      </Button>
    </Tooltip>
  )
}

const PipelineBoard = ({
  pipeline,
  boardData,
  refetch,
  filters,
  pipelineLoading,
  onUpdate,
  onEdit,
}) => {
  const [board, setBoard] = useState(boardData)

  const theme = useTheme()
  const styles = getBoardStyles({ theme })

  const [createStage, { loading: createStageLoading }] = useMutation(
    CREATE_STAGE_FROM_BOARD,
    {
      onCompleted: (_) => {
        refetch()
      },
    }
  )
  const [updateStage, { loading: updateStageLoading }] = useMutation(
    UPDATE_STAGE_FROM_BOARD,
    {
      onCompleted: (_) => {
        refetch()
      },
    }
  )

  const [updateOpportunity, { loading: updateOpportunityLoading }] =
    useMutation(UPDATE_OPPORTUNITY_FROM_BOARD, {
      onCompleted: (_) => {
        refetch()
      },
    })

  const debouncedUpdateStage = useCallback(debounce(updateStage, 500), [
    updateStage,
  ])

  const onCardDragEnd = async (card, source, destination) => {
    // Check if the drag-and-drop operation was cancelled
    if (!destination) {
      return
    }
    const { fromColumnId, fromPosition } = source
    const { toColumnId, toPosition } = destination

    const newBoard = JSON.parse(JSON.stringify(board))

    const sourceColumn = newBoard.columns.find(
      (column) => column.id === fromColumnId
    )
    const destinationColumn = newBoard.columns.find(
      (column) => column.id === toColumnId
    )

    const [movedCard] = sourceColumn.cards.splice(fromPosition, 1)
    if (fromColumnId === toColumnId && fromPosition < toPosition) {
      destinationColumn.cards.splice(toPosition - 1, 0, movedCard)
    } else {
      destinationColumn.cards.splice(toPosition, 0, movedCard)
    }

    setBoard(newBoard)
    onUpdate()

    await updateOpportunity({
      variables: {
        input: {
          id: movedCard.id,
          stageId: destinationColumn.id,
          position: toPosition,
          pipelineId: pipeline.id,
          workspaceId: pipeline.workspaceId,
        },
      },
    })
  }

  const onRemoveCard = (columnId, cardId) => {
    logger.dev({ columnId, cardId })
    // update Opportunity
  }

  const onColumnDragEnd = async (source, destination, subject) => {
    if (!destination) {
      return
    }

    const { fromPosition } = destination
    const { toPosition } = subject
    const newBoard = JSON.parse(JSON.stringify(board))
    const [movedColumn] = newBoard.columns.splice(fromPosition, 1)
    newBoard.columns.splice(toPosition, 0, movedColumn)
    setBoard(newBoard)

    onUpdate()

    await updateStage({
      variables: {
        input: {
          id: source.id,
          position: toPosition,
          pipelineId: pipeline.id,
        },
      },
    })
  }

  const onRenameColumn = (columnId, newName) => {
    const payload = {
      variables: {
        input: {
          id: columnId,
          title: newName,
        },
      },
    }

    onUpdate()
    debouncedUpdateStage(payload)
  }

  const onEditColumn = (columnId, newColumnDetails) => {
    logger.dev({ columnId, newColumnDetails })
  }

  const onNewColumn = async (columnDetails) => {
    onUpdate()
    const newBoard = JSON.parse(JSON.stringify(board))
    const newPosition = newBoard.columns.push(columnDetails) - 1

    setBoard(newBoard)

    await createStage({
      variables: {
        input: {
          title: columnDetails.title,
          workspaceId: pipeline.workspaceId,
          pipelineId: pipeline.id,
          position: newPosition,
          entranceCriteria: [],
          likelihoodToClose: 0.5,
        },
      },
    })
  }

  const shouldShowCard = (opportunity) => {
    return shouldShowOpp(opportunity, filters)
  }
  useEffect(() => {
    if (boardData) {
      const filteredBoard = {
        ...boardData,
        columns: boardData.columns.map((column) => ({
          ...column,
          cards: column.cards.filter((card) => shouldShowCard(card)),
        })),
      }

      setBoard(filteredBoard)
    } else {
      setBoard(boardData)
    }
  }, [boardData, filters])

  const loading = useMemo(() => {
    return (
      !board ||
      createStageLoading ||
      updateStageLoading ||
      updateOpportunityLoading ||
      pipelineLoading
    )
  }, [
    board,
    createStageLoading,
    updateStageLoading,
    updateOpportunityLoading,
    pipelineLoading,
  ])

  return (
    <Box sx={styles}>
      <LinearProgress
        sx={{
          opacity: loading ? 1.0 : 0.0,
          transition: 'all 0.6s ease-in-out',
          height: '1px',
        }}
        color="secondary"
      />
      <Board
        renderCard={(data, { dragging }) =>
          shouldShowCard(data) ? (
            <PipelineBoardCard
              dragging={dragging}
              data={data}
              pipeline={pipeline}
              refetch={refetch}
              loading={loading}
            />
          ) : (
            <Box sx={{ display: 'none' }} />
          )
        }
        renderColumnHeader={(column) => {
          return (
            <PipelineBoardColumnHeader
              column={column}
              updateColumn={onEditColumn}
              deleteColumn={() => {}}
              renameColumn={onRenameColumn}
              pipeline={pipeline}
              refetch={refetch}
              onEdit={onEdit}
            />
          )
        }}
        allowAddColumn={true}
        allowRemoveColumn={true}
        renderColumnAdder={() => {
          return <ColumnAdder addColumn={onNewColumn} />
        }}
        onCardDragEnd={onCardDragEnd}
        onColumnDragEnd={onColumnDragEnd}
        onNewColumn={onNewColumn}
        onRemoveCard={onRemoveCard}
        onRemoveColumn={() => {}}
        onEditColumn={onEditColumn}
        onRenameColumn={onRenameColumn}
      >
        {board}
      </Board>
    </Box>
  )
}

export default PipelineBoard
