import { useContext, useMemo } from 'react'

import { Avatar } from '@mui/material'
import { RiBuilding2Fill } from '@remixicon/react'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { extractEmailDomain, isFreemailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { getCompanyLogoUrl } from 'src/lib/formatters'

const GET_CORE_COMPANY_FOR_DOMAIN_AVATAR = gql`
  query GetCoreCompanyForDomainAvatar($domain: String!) {
    coreCompany(domain: $domain) {
      id
      name
      photoSquare
      colorVibrant
    }
  }
`

const GET_ORGANIZATION_FOR_DOMAIN_AVATAR = gql`
  query GetOrganizationForDomainAvatar(
    $domain: String!
    $workspaceId: String!
  ) {
    workspaceOrganization(domain: $domain, workspaceId: $workspaceId) {
      id
      name
      photos {
        square
      }
      colorVibrant
      colors {
        colorDarkMuted
        colorLightMuted
        colorVibrant
        colorDarkVibrant
        colorLightVibrant
      }
    }
  }
`

const DomainAvatar = ({
  email,
  size,
  border,
  domain,
  photoUrl,
  borderRadius = 1000,
  companyColor,
  onClick,
  sx = {},
}: {
  email?: string
  size?: number
  border?: number
  domain?: string
  photoUrl?: string
  borderRadius?: number
  companyColor?: string
  onClick?: () => void
  sx?: any
}) => {
  const { currentUser: user } = useAuth()
  const { selectedWorkspace, orgsByDomain } = useContext(DayContext)
  const preloadedPhotoUrl = useMemo(
    () => orgsByDomain[domain]?.photos?.square,
    [orgsByDomain, domain]
  )

  const { data: coreCompanyData } = useQuery(
    GET_CORE_COMPANY_FOR_DOMAIN_AVATAR,
    {
      variables: { domain },
      skip:
        !!preloadedPhotoUrl ||
        !domain ||
        !!photoUrl ||
        isFreemailDomain(domain),
    }
  )

  const { data: organizationData } = useQuery(
    GET_ORGANIZATION_FOR_DOMAIN_AVATAR,
    {
      variables: { domain, workspaceId: selectedWorkspace },
      skip:
        !!preloadedPhotoUrl ||
        !selectedWorkspace ||
        !domain ||
        !!photoUrl ||
        isFreemailDomain(domain),
    }
  )

  if (!domain && email) domain = extractEmailDomain(email)
  if (!domain) domain = ''
  domain = domain.toLowerCase().trim()

  const finalPhotoUrl = useMemo(() => {
    return (
      photoUrl ||
      preloadedPhotoUrl ||
      organizationData?.workspaceOrganization?.photos?.square ||
      coreCompanyData?.coreCompany?.photoSquare
    )
  }, [photoUrl, preloadedPhotoUrl, organizationData, coreCompanyData])

  const finalCompanyColor = useMemo(() => {
    return (
      companyColor ||
      organizationData?.workspaceOrganization?.colors?.colorVibrant ||
      coreCompanyData?.coreCompany?.colorVibrant
    )
  }, [companyColor, organizationData, coreCompanyData])

  const fontSize: string = size ? `${Math.floor(0.4 * size)}px` : '48px'
  const letter = <RiBuilding2Fill size={size ? Math.floor(0.5 * size) : 36} /> //domain.slice(0, 1)
  if (!border) border = 0
  const overridePhotoUrl = domain
    ? getCompanyLogoUrl({ domain, photoUrl: finalPhotoUrl })
    : finalPhotoUrl

  return (
    <Avatar
      className="domainAvatar"
      onClick={() => {
        if (onClick) onClick()
      }}
      src={overridePhotoUrl}
      sx={{
        height: `${size}px`,
        width: `${size}px`,
        background: (theme) =>
          overridePhotoUrl
            ? 'transparent'
            : finalCompanyColor || theme.palette.divider,
        border: (theme) =>
          `${border}px solid ${theme.palette.divider} !important`,
        borderRadius: `${borderRadius}px`,
        color: (theme) => theme.palette.primary.contrastText,
        fontSize,
        fontWeight: 600,
        textDecoration: 'none',
        '&:not(a)': {
          textDecoration: 'none',
        },
        '& img.MuiAvatar-img': {
          objectFit: 'contain',
        },
        ...sx,
      }}
    >
      {letter}
    </Avatar>
  )
}

export default DomainAvatar
