import { IconButton, Typography } from '@mui/material'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium'
import {
  RiAlignJustify,
  RiDownloadCloudLine,
  RiFilter3Line,
  RiLinkedinFill,
  RiSearchLine,
} from '@remixicon/react'
import LayoutColumnLineIcon from 'remixicon-react/LayoutColumnLineIcon'

import Row from 'src/components/Row/Row'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { getRelationshipSummaryTypeMetadataFromKey } from 'src/lib/relationshipSummary'

import ContactAvatar from '../ContactAvatar/ContactAvatar'
import OrganizationChip from '../Organizations/OrganizationChip/OrganizationChip'
import RelationshipTypeChip from '../RelationshipTypeChip/RelationshipTypeChip'

export const getDataTableRenderers = ({ setSidebarObject }) => {
  return {
    headers: {},
    cells: {
      photoUrl: (params) => {
        return (
          <ContactAvatar
            email={params?.row?.email}
            size={28}
          />
        )
      },
      companyName: (params) => {
        return (
          <OrganizationChip
            domain={extractEmailDomain(params.row?.email)}
            size="small"
            sx={{
              fontWeight: 500,
              '& .domainAvatar': {
                mr: 0,
              },
              width: '100%',
            }}
          />
        )
      },
      relationshipType: (params) => {
        return <RelationshipTypeChip type={params.row?.relationship?.type} />
      },
      linkedInUrl: (params) => {
        return (
          params.row?.linkedInUrl && (
            <Row sx={{ justifyContent: 'center', width: '100%' }}>
              <IconButton
                sx={{
                  backgroundColor: '#2867B2',
                  '&:hover': {
                    backgroundColor: '#2867B2',
                  },
                  padding: '2px',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  window.open(params.row?.linkedInUrl, '_blank')
                }}
              >
                <RiLinkedinFill
                  size={16}
                  style={{
                    color: 'white',
                    borderRadius: 2,
                  }}
                />
              </IconButton>
            </Row>
          )
        )
      },
    },
  }
}

const standardWidth = 16

export const getDefaultColumnsContact = ({ DataTableRenderers }) => {
  return [
    {
      field: 'photoUrl',
      headerName: 'Photo',
      flex: 0,
      width: standardWidth * 3,
      editable: false,
      renderCell: DataTableRenderers.cells.photoUrl,
    },
    {
      field: 'fullName',
      headerName: 'Name',
      flex: 0,
      width: standardWidth * 14,
      editable: false,
      valueGetter: (params) => {
        return params.row.fullName || params.row.email
      },
      groupable: false,
    },
    {
      field: 'currentJobTitle',
      headerName: 'Title',
      flex: 0,
      width: standardWidth * 10,
      editable: false,
    },
    {
      field: 'linkedInUrl',
      headerName: '',
      flex: 0,
      width: standardWidth * 1,
      editable: false,
      renderCell: DataTableRenderers.cells.linkedInUrl,
    },
    {
      field: 'relationshipSummary/oneSentence',
      headerName: 'Current Status',
      flex: 1,
      width: standardWidth * 14,
      editable: false,
      groupable: false,
      sortable: false,
      valueGetter: (params) => {
        return params.row.relationship?.oneSentence
      },
    },
    {
      field: 'relationshipSummary/type',
      headerName: 'Type',
      width: 200,
      editable: false,
      renderCell: DataTableRenderers.cells.relationshipType,
      valueGetter: (params) => {
        const typeObject = getRelationshipSummaryTypeMetadataFromKey(
          params.row['relationshipSummary/type']
        )
        return typeObject?.label || ''
      },
    },
    {
      field: 'companyName',
      headerName: 'Organization',
      width: 220,
      editable: false,
      renderCell: DataTableRenderers.cells.companyName,
      valueGetter: (params) => {
        return extractEmailDomain(params.row?.id)
      },
    },
  ]
}

export const DataGridToolbar = (props) => {
  const iconStyle = {
    width: '16px',
    height: '16px',
    fontWeight: 500,
    flexShrink: 0,
  }
  return (
    <GridToolbarContainer className="data-grid-toolbar">
      <Row
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          height: '64px !important',
          '& .MuiButton-root': {
            mr: 3,
            px: 0,
          },
        }}
      >
        <Row>
          {props.title && (
            <Typography
              variant="h2"
              sx={{ pl: props.titleMargin || 0, pr: 2 }}
            >
              {props.title}
            </Typography>
          )}
          {props.customFilters && React.cloneElement(props.customFilters)}
          {props.showColumnFilter && (
            <GridToolbarColumnsButton
              startIcon={<LayoutColumnLineIcon style={iconStyle} />}
            />
          )}
          {props.showFilter && (
            <GridToolbarFilterButton
              componentsProps={{
                button: {
                  startIcon: <RiFilter3Line style={iconStyle} />,
                },
              }}
            />
          )}
          {props.showDensitySelector && (
            <GridToolbarDensitySelector
              startIcon={<RiAlignJustify style={iconStyle} />}
            />
          )}
          {props.showExport && (
            <GridToolbarExport
              csvOptions={props.csvOptions}
              startIcon={<RiDownloadCloudLine style={iconStyle} />}
            />
          )}
        </Row>
        {props.showQuickFilter && (
          <GridToolbarQuickFilter
            InputProps={{
              startAdornment: <RiSearchLine size={16} />,
              placeholder: 'Search ...',
              disableUnderline: true,
              sx: {
                m: 0,
                p: 0,
                fontSize: '0.8rem',
                fontWeight: 500,
                '& .MuiInput-input': {
                  padding: 0,
                  fontSize: '0.8rem',
                  fontWeight: 500,
                },
                '& .MuiInputBase-adornedStart': {
                  marginRight: '4px',
                  marginLeft: 0,
                },
              },
            }}
          />
        )}
      </Row>
    </GridToolbarContainer>
  )
}

export const StandardDataTableSx = {
  '.MuiDataGrid-cell': {
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '-0.4px',
    color: (theme) => theme.palette.text.secondary,
    cursor: 'pointer',
  },
  '& .MuiDataGrid-toolbarContainer': {
    border: 'none',
    p: 0,
  },
  '.MuiDataGrid-main': {
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
  },
}
