export const GlobalWorkspace = 'global'

export const NativeObjectTypes = {
  Person: 'native_contact',
  Contact: 'native_contact',
  Organization: 'native_organization',
  Opportunity: 'native_opportunity',
  Pipeline: 'native_pipeline',
  Stage: 'native_stage',
  MeetingRecording: 'native_meetingrecording',
  MeetingRecordingClip: 'native_meetingrecordingclip',
  Page: 'native_page',
  Action: 'native_action',
} as const

export const PropertyTypes = {
  Address: 'address',
  Calculated: 'calculated',
  Combobox: 'combobox',
  Currency: 'currency',
  Email: 'email',
  MultiPicklist: 'multipicklist',
  Percent: 'percent',
  Picklist: 'picklist',
  Phone: 'phone',
  ObjectReference: 'reference',
  TextArea: 'textarea',
  Url: 'url',
  DateTime: 'datetime',
} as const

export const ObjectAccessLevels = {
  Owner: 'owner',
  FullAccess: 'fullAccess',
  Editor: 'editor',
  Collaborator: 'collaborator',
  Viewer: 'viewer',
} as const

export const mergeObjects = (obj1: any, obj2: any): any => {
  if (!obj1 || typeof obj1 !== 'object') return obj2
  if (!obj2 || typeof obj2 !== 'object') return obj1

  const result = { ...obj1 }

  for (const key in obj2) {
    if (obj2[key] === null) {
      continue // Skip null values in obj2
    } else if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key])) {
      result[key] = mergeObjects(obj1[key], obj2[key]) // Recursively merge nested objects
    } else {
      result[key] = obj2[key] // Use non-null values from obj2
    }
  }

  return result
}
