import { useContext } from 'react'

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { Action, Opportunity } from 'types/graphql'

import { DayContext } from 'src/lib/dayContext'

import AvatarRow from '../../AvatarRow/AvatarRow'
import ContactTile from '../../ContactTile/ContactTile'
import OpportunityChip from '../../OpportunityChip/OpportunityChip'
import OrganizationTile from '../../Organizations/OrganizationTile/OrganizationTile'
import Row from '../../Row/Row'
import ActionActions from '../ActionActions/ActionActions'
import ActionChannelChip from '../ActionChannelChip/ActionChannelChip'
import ActionStatusChip from '../ActionStatusChip/ActionStatusChip'
import ActionTypeChip from '../ActionTypeChip/ActionTypeChip'

const ActionDialog = ({
  action,
  opportunities,
  onUpdate,
  onClose,
}: {
  action: Action
  opportunities?: Opportunity[]
  onUpdate: () => void
  onClose: () => void
}) => {
  const { internalDomains } = useContext(DayContext)
  return (
    action && (
      <Dialog
        open={!!action}
        onClose={onClose}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>{action?.title}</DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            sx={{ mb: 4 }}
          >
            {action?.reasoning}
          </Typography>
          <Row
            sx={{ justifyContent: 'space-between', alignItems: 'top', mt: 3 }}
            gap={3}
          >
            <Box>
              {action.people?.length > 0 && (
                <>
                  {action.people.length < 4 ? (
                    <>
                      {action.people.map((person) => {
                        const email =
                          typeof person === 'string' ? person : person.email
                        return (
                          <Box
                            key={`person-${email}-action-${action.id}`}
                            sx={{ mb: 3 }}
                          >
                            <ContactTile
                              key={`person-${email}-action-${action.id}`}
                              email={email}
                              size={48}
                            />
                          </Box>
                        )
                      })}
                    </>
                  ) : (
                    <AvatarRow
                      emails={action.people?.map((person) => person.email)}
                      size={48}
                    />
                  )}
                </>
              )}
              {action.organizations
                ?.filter(
                  (org) =>
                    !!org?.domain && !internalDomains.includes(org.domain)
                )
                .map((organization) => (
                  <Box
                    key={`organization-${organization.domain}-action-${action.id}`}
                    sx={{ mb: 3 }}
                  >
                    <OrganizationTile
                      domain={organization.domain}
                      openSidebar={false}
                    />
                  </Box>
                ))}
            </Box>
            <Row
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'top',
                flexDirection: 'column',
                width: '192px',
                flexShrink: 0,
              }}
              gap={2}
            >
              <ActionActions
                action={action}
                showButtons={true}
                onUpdate={onUpdate}
              />
              <ActionTypeChip type={action?.type} />
              <ActionStatusChip status={action?.status} />
              {opportunities?.map((opportunity) => (
                <OpportunityChip
                  key={`opportunity-${opportunity.id}-action-${action.id}`}
                  opportunity={opportunity}
                />
              ))}

              <ActionChannelChip
                action={action}
                width="100%"
              />
            </Row>
          </Row>
        </DialogContent>
      </Dialog>
    )
  )
}

export default ActionDialog
