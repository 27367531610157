import { useContext, useMemo } from 'react'

import { Avatar, Box, CircularProgress, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import ContactAvatar from '../ContactAvatar/ContactAvatar'

const GET_PERSON_TILE_FOR_AVATAR = gql`
  query GetPersonAvatarForTile($email: String!, $workspaceId: String!) {
    getPerson(email: $email, workspaceId: $workspaceId) {
      id
      fullName
      photoUrl
      currentJobTitle
    }
  }
`

const ContactTile = ({
  email,
  displayName = '',
  size = 32,
  showSidebar = true,
  missingInfoExplanation = 'No other contact info available',
  subtitle = true,
}: {
  email: string
  displayName?: string
  size?: number
  showSidebar?: boolean
  missingInfoExplanation?: string
  subtitle?: boolean | string
}) => {
  const {
    setSidebarObject: setSidebar,
    workspacePeople,
    selectedWorkspace,
  } = useContext(DayContext)

  const personFromWorkspace = useMemo(() => {
    return workspacePeople.find((p) => p.email === email)
  }, [workspacePeople, email])

  const { data: personData } = useQuery(GET_PERSON_TILE_FOR_AVATAR, {
    variables: { email, workspaceId: selectedWorkspace },
    skip:
      !email ||
      email === 'assistant@day.ai' ||
      !selectedWorkspace ||
      !!personFromWorkspace,
  })

  const person = personData?.getPerson || personFromWorkspace

  return person ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        cursor: email && showSidebar ? 'pointer' : 'inherit',
      }}
      onClick={() => {
        if (showSidebar)
          setSidebar({
            objectId: email,
            objectType: NativeObjectTypes.Contact,
            properties: person,
          })
      }}
    >
      {email && person?.photoUrl ? (
        <Avatar
          src={person?.photoUrl}
          sx={{ width: `${size}px`, height: `${size}px` }}
        />
      ) : (
        <ContactAvatar
          email={email}
          size={size}
          borderRadius={100}
        />
      )}
      <Box
        sx={{
          ml: size > 30 ? 2 : 1,
          width: `calc(100% - ${size}px - ${size > 30 ? 16 : 8}px)`,
        }}
      >
        <Typography
          sx={{
            width: '100%',
            fontWeight: 600,
            fontSize: size > 30 ? '0.8rem' : '0.7rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {person?.fullName || displayName || email}
        </Typography>
        {subtitle && (email || missingInfoExplanation) && (
          <Typography
            sx={{
              width: '100%',
              fontWeight: 400,
              fontSize: size > 30 ? '0.6rem' : '0.5rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {typeof subtitle === 'string'
              ? subtitle
              : person?.currentJobTitle
              ? person?.currentJobTitle
              : email || missingInfoExplanation}
          </Typography>
        )}
      </Box>
    </Box>
  ) : (
    <CircularProgress
      color="secondary"
      size={`calc(${size}px - 4px)`}
    />
  )
}

export default ContactTile
