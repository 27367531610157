import { createTheme, darken, lighten, ThemeProvider } from '@mui/material'
import { ConfirmProvider } from 'material-ui-confirm'
import { WorkspaceDomainClaim } from 'types/graphql'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import type {} from '@mui/x-data-grid-premium/themeAugmentation'

import { AuthProvider, useAuth } from 'src/auth'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import './scaffold.css'
import './index.css'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

export const themeBlue = createTheme({
  typography: {
    fontFamily: ['Poppins'].join(','),
    h1: {
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '130%',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontWeight: 600,
      fontSize: '24px',
      letterSpacing: '-1.2px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '18px',
      mb: 2,
      letterSpacing: '-0.9px',
    },
    h4: {
      fontWeight: 500,
      fontSize: '16px',
      letterSpacing: '-0.2px',
    },
    h5: {
      fontSize: '14px',
      lineHeight: '130%',
      fontWeight: 500,
      letterSpacing: '-0.2px',
    },
    body1: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: '-0.10px',
      color: '#354052',
    },
    body2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%',
      letterSpacing: '-0.22px',
      color: '#354052',
    },
  },
  palette: {
    background: {
      default: '#F9FAFB',
      paper: '#FFFFFF',
    },
    primary: {
      main: '#37352f',
      contrastText: lighten('#FEEFD9', 0.9),
    },
    secondary: {
      main: '#3F7EB4', // '#007aff',
      light: 'rgba(0, 145, 228, 0.2)',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#4F7FAF',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#EEB057',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#CA3A52',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#5FC18F',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#37352f', //'#231F20', //'#101925',
      secondary: '#354052',
      disabled: '#888888',
    },
    action: {
      selected: darken('#F5F7FA', 0.03),
    },
    divider: darken('#F5F7FA', 0.06),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: 'none',
          borderRadius: 6,
          '&.MuiButton-primaryOutlined': {
            color: '#231F20',
          },
          '&.MuiButton-sizeMedium': {
            borderRadius: 4,
          },
          '&.MuiButton-sizeSmall': {
            borderRadius: 4,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '12px !important',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 0,
        },
        indicator: { backgroundColor: '#231F20' },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '28px',
          textTransform: 'none',
          color: '#231F20',
          '&.Mui-selected': {
            fontWeight: 600,
            color: '#231F20',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.1rem',
          letterSpacing: '-0.8px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          '& .MuiChip-label': {
            fontWeight: 500,
            fontSize: '11px',
            letterSpacing: '-0.2px',
            lineHeight: '120%',
            overflowY: 'hidden',
            pl: '4px',
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
          },
          '& .MuiChip-icon': {
            height: '16px',
            width: '16px',
          },
          height: '24px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '24px',
          borderRadius: '8px',
          boxShadow: `
            0 1px 2px rgba(136, 161, 183, 0.07),
            0 2px 4px rgba(136, 161, 183, 0.07),
            0 4px 8px rgba(136, 161, 183, 0.07),
            0 8px 16px rgba(136, 161, 183, 0.07),
            0 16px 32px rgba(136, 161, 183, 0.07),
            0 32px 64px rgba(136, 161, 183, 0.07)
          `,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: `0 0 36px 0`,
          margin: 0,
          '& .MuiTypography-h5': {
            fontSize: '18px',
            fontWeight: 600,
          },
          '& .MuiCardHeader-subheader': {
            fontSize: '14px',
            fontWeight: 400,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0 0 16px 0',
          margin: '0',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            borderBottom: 0,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
          body {
            -webkit-font-smoothing: antialiased;
          }
        `,
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'orange',
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root ': {
            fontSize: '0.8rem',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        menu: {
          '& .MuiListItemIcon-root': {
            '& .MuiSvgIcon-root': {
              fontSize: '1.0rem',
            },
          },
          '& .MuiListItemText-root .MuiTypography-root': { fontSize: '0.8rem' },
        },
        main: {},
        toolbarContainer: {
          height: '64px',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '14px',
          paddingRight: '24px',
          '& .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall':
            {
              fontSize: '0.7rem',
            },
          '& .MuiTextField-root': {
            padding: 0,
          },
        },
        panelWrapper: {
          '& .MuiInput-root': {
            borderBottom: 'none',
            '&:hover': {
              borderBottom: 'none',
            },
            '&::before': {
              borderBottom: 'none',
            },
            '&::after': {
              borderBottom: 'none',
            },
          },
          '& .MuiButtonBase-root': {
            fontSize: '0.7rem',
          },
        },
        root: {
          borderCollapse: 'collapse',
          borderSpacing: 0,
          '& .MuiSvgIcon-root': {
            fontSize: '1.0rem',
            '& .MuiSvgIcon-fontSizeMedium': {},
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: `1px solid ${lighten('#37352f', 0.9)}`,
            //backgroundColor: '#F5F7FA',
          },
          '& .MuiDataGrid-columnHeader': {
            //backgroundColor: '#888888',
          },
          // TH:
          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            fontSize: '0.8rem',
          },
          '& .MuiDataGrid-cell': {
            fontSize: '0.8rem',
            borderRight: `1px solid ${lighten('#37352f', 0.9)}`,
            borderBottom: `1px solid ${lighten('#37352f', 0.9)}`,
            // last cell in row:
            '&:last-child': {
              borderRight: 0,
            },
          },
          '& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within':
            {
              outline: 'none',
            },
          '& .MuiTablePagination-toolbar, .MuiTablePagination-selectLabel, .MuiTablePagination-actions button, .MuiTablePagination-displayedRows, .MuiDataGrid-rowCount, .MuiDataGrid-selectedRowCount, .MuiFormControlLabel-label':
            {
              fontSize: '0.8rem',
            },
        },
      },
    },
  },
})

const App = () => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodProvider titleTemplate="%PageTitle">
      <AuthProvider>
        <RedwoodApolloProvider
          useAuth={useAuth}
          graphQLClientConfig={{
            cacheConfig: {
              typePolicies: {
                Workspace: {
                  fields: {
                    domains: {
                      merge(_existing = [], incoming: WorkspaceDomainClaim[]) {
                        return incoming
                      },
                    },
                  },
                },
                WorkspaceMember: {
                  keyFields: ['id', 'email', 'status', 'roleId'],
                },
                CRMObject: {
                  keyFields: ['objectType', 'objectId'],
                },
                WorkAccountWorkspaceConnection: {
                  keyFields: ['workAccountUuid'],
                },
              },
            },
          }}
        >
          <ThemeProvider theme={themeBlue}>
            <ConfirmProvider>
              <Routes />
            </ConfirmProvider>
          </ThemeProvider>
        </RedwoodApolloProvider>
      </AuthProvider>
    </RedwoodProvider>
  </FatalErrorBoundary>
)

export default App
