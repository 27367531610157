import { useContext } from 'react'

import { Chip, darken, lighten } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { isFreemailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import DomainAvatar from '../../DomainAvatar/DomainAvatar'

// get coreCompany from domain
const GET_ORGANIZATION_FOR_CHIP = gql`
  query GetOrganizationForChip($domain: String!, $workspaceId: String!) {
    workspaceOrganization(domain: $domain, workspaceId: $workspaceId) {
      id
      name
      domain
      colors {
        colorVibrant
        colorDarkVibrant
        colorLightVibrant
        colorMuted
        colorDarkMuted
        colorLightMuted
      }
      photos {
        square
      }
    }
  }
`

const OrganizationChip = ({
  domain,
  name = null,
  variant = 'outlined',
  size = 'medium',
  sx = {},
}: {
  domain: string
  name?: string
  variant?: 'filled' | 'outlined'
  size?: 'medium' | 'small'
  sx?: any
}) => {
  const isFreemail = isFreemailDomain(domain)

  const { setSidebarObject, selectedWorkspace } = useContext(DayContext)
  const { data } = useQuery(GET_ORGANIZATION_FOR_CHIP, {
    variables: { domain, workspaceId: selectedWorkspace },
    skip: isFreemail || !domain || !selectedWorkspace,
  })
  const organization = data?.workspaceOrganization

  const iconSize = size === 'small' ? 16 : 20

  return (
    !isFreemail &&
    domain && (
      <Chip
        variant={variant}
        icon={
          <DomainAvatar
            domain={domain}
            photoUrl={organization ? organization?.photos?.square : null}
            size={iconSize}
            companyColor={
              organization ? organization?.colors?.colorVibrant : null
            }
            borderRadius={50}
            sx={{
              mr: '-4px',
            }}
          />
        }
        label={organization?.name || name || domain}
        clickable={true}
        size={size}
        onClick={(e) => {
          e.stopPropagation()
          setSidebarObject({
            objectType: NativeObjectTypes.Organization,
            objectId: domain,
            properties: organization,
          })
        }}
        sx={{
          px: '4px',
          justifyContent: 'start',
          borderRadius: '50px',
          background: 'transparent',
          fontSize: size === 'small' ? '0.75rem' : '0.8rem',
          '&:hover': {
            background: (theme) =>
              `${
                organization?.colorVibrant
                  ? lighten(organization?.colorVibrant, 0.8)
                  : theme.palette.primary.dark
              } !important`,
            color: (theme) =>
              organization?.colorVibrant
                ? darken(organization?.colorVibrant, 0.4)
                : theme.palette.primary.contrastText,
          },
          ...sx,
        }}
      />
    )
  )
}

export default OrganizationChip
