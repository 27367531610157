import { useContext, useEffect, useMemo, useState } from 'react'

import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'
import { RiAddLine, RiCloseLine } from '@remixicon/react'
import { useConfirm } from 'material-ui-confirm'
import { Opportunity, RoleType } from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import ContactTile from 'src/components/ContactTile/ContactTile'
import ObjectFinder from 'src/components/ObjectFinder/ObjectFinder'
import Row from 'src/components/Row/Row'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'
import { ensureArray } from 'src/lib/safeUi'

const OPPORTUNITY_ROLES_QUERY = gql`
  query OpportunityRolesQuery($workspaceId: String!, $id: String!) {
    opportunityRoles(workspaceId: $workspaceId, id: $id) {
      id
      personRoles {
        personEmail
        roles
        lastContactAt
      }
      updatedAt
    }
  }
`

const UPSERT_OPPORTUNITY_ROLES_MUTATION = gql`
  mutation UpsertOpportunityRolesMutation(
    $input: UpsertOpportunityRolesInput!
  ) {
    upsertOpportunityRoles(input: $input) {
      id
      personRoles {
        personEmail
        roles
        lastContactAt
      }
      updatedAt
    }
  }
`

/*
  enum RoleType {
    DECISION_MAKER
    PRIMARY_CONTACT
    SUPPORTER
    DETRACTOR
    CHAMPION
    ECONOMIC_BUYER
    OWNER_EMAIL
    HURDLE
    OTHER
    IGNORE
    DIRECT_BENEFIT
  }
*/
export const possibleRoles = [
  {
    key: 'DECISION_MAKER',
    label: 'Decision Maker',
    showInChooser: false,
  },
  {
    key: 'PRIMARY_CONTACT',
    label: 'Primary Contact',
    showInChooser: true,
  },
  {
    key: 'CHAMPION',
    label: 'Champion',
    showInChooser: true,
  },
  {
    key: 'SUPPORTER',
    label: 'Supporter',
    showInChooser: true,
  },
  {
    key: 'DETRACTOR',
    label: 'Skeptic',
    showInChooser: true,
  },
  {
    key: 'OWNER_EMAIL',
    label: 'Owner',
    showInChooser: false,
  },
  {
    key: 'HURDLE',
    label: 'Hurdle',
    showInChooser: false,
  },
  {
    key: 'ECONOMIC_BUYER',
    label: 'Economic Buyer',
    showInChooser: true,
  },
  {
    key: 'OTHER',
    label: 'Other',
    showInChooser: false,
  },
  {
    key: 'IGNORE',
    label: 'Ignore',
    showInChooser: false,
  },
  {
    key: 'DIRECT_BENEFIT',
    label: 'Benefits directly',
    showInChooser: false,
  },
]

const OpportunityRolesList = ({
  id,
  opportunity = null,
  showHeader = true,
}: {
  id: string
  opportunity?: Opportunity
  showHeader?: boolean
}) => {
  const confirm = useConfirm()
  const { selectedWorkspace, workspaces } = useContext(DayContext)
  const [roles, setRoles] = useState<any[]>([])
  const [initialized, setInitialized] = useState(false)
  const [showAddPerson, setShowAddPerson] = useState(false)

  if (!roles?.length && opportunity?.roles?.length > 0) {
    setRoles(opportunity?.roles || [])
  }

  const currentWorkspace = useMemo(() => {
    return workspaces.find((workspace) => workspace.id === selectedWorkspace)
  }, [workspaces, selectedWorkspace])

  const { data, loading, error } = useQuery(OPPORTUNITY_ROLES_QUERY, {
    variables: { workspaceId: selectedWorkspace, id },
    skip: !id || !selectedWorkspace,
    onCompleted: (result) => {
      setRoles(
        result.opportunityRoles?.personRoles.filter(
          (role) => !!role.personEmail
        ) || []
      )
    },
  })
  logger.dev({ data, roles, loading })

  useEffect(() => {
    if (data) {
      handleRolesChange()
    }
  }, [roles])

  const [
    upsertOpportunityRoles,
    {
      loading: upsertOpportunityRolesLoading,
      error: upsertOpportunityRolesError,
    },
  ] = useMutation(UPSERT_OPPORTUNITY_ROLES_MUTATION)

  const handleRolesChange = () => {
    if (!roles) {
      logger.warn(`No roles found for ${id}`)
      return
    }
    logger.dev({ roles })
    upsertOpportunityRoles({
      variables: {
        input: {
          id,
          workspaceId: selectedWorkspace,
          personRoles: roles,
        },
      },
    })
  }

  const shouldShowRoleEntry = (role: any) => {
    const allWorkspaceMemberEmails = currentWorkspace.members.map(
      (member) => member.email
    )
    if (allWorkspaceMemberEmails.includes(role.personEmail)) {
      return false
    }
    const allDomains = new Set<string>(
      currentWorkspace?.domains?.map((domain) => domain.domain).filter(Boolean)
    )
    for (const email of allWorkspaceMemberEmails) {
      const emailDomain = extractEmailDomain(email)
      if (emailDomain) {
        allDomains.add(emailDomain)
      }
    }

    const domain = extractEmailDomain(role.personEmail)
    return !allDomains.has(domain)
  }

  return (
    <>
      {loading && !roles?.length ? (
        <CircularProgress
          color="secondary"
          size={24}
        />
      ) : (
        <Box sx={{ mb: 5 }}>
          <Row sx={{ justifyContent: 'space-between' }}>
            {showHeader && <Typography variant="h2">People</Typography>}
            <Button
              variant="text"
              size="small"
              startIcon={<RiAddLine size={16} />}
              onClick={() => setShowAddPerson(true)}
              sx={{ width: '114px' }}
            >
              Add person
            </Button>
          </Row>
          {roles &&
            roles.length > 0 &&
            roles?.map(
              (role, index) =>
                shouldShowRoleEntry(role) && (
                  <Box
                    key={`role_${index}_${role.personEmail}`}
                    sx={{ mb: 1 }}
                  >
                    <Row
                      sx={{
                        width: '100%',
                        height: '64px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{ width: '184px' }}>
                        <ContactTile email={role.personEmail} />
                      </Box>
                      <Row sx={{ justifyContent: 'right' }}>
                        <FormControl
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiInputLabel-root': {
                              top: '-12px',
                              left: '242px',
                            },
                            '& .MuiInputLabel-shrink': {
                              display: 'none',
                            },
                            '& .MuiSelect-multiple > .MuiBox-root': {
                              justifyContent: 'right',
                            },
                            '& .MuiMenu-paper, .MuiPopover-paper, .MuiMenu-paper, .MuiPaper-rounded':
                              {
                                borderRadius: '12px !important',
                              },
                            '& .MuiList-root': {
                              p: 0,
                              borderRadius: '12px',
                            },
                            width: '380px',
                            flexShrink: 0,
                          }}
                        >
                          <InputLabel sx={{}}>
                            <Row gap={1}>
                              <Chip
                                label="Add roles"
                                size="small"
                                variant="outlined"
                                icon={<RiAddLine size={14} />}
                                clickable={true}
                                color="secondary"
                                sx={{
                                  width: '116px',
                                }}
                              />
                            </Row>
                          </InputLabel>
                          <Select
                            placeholder="Select roles"
                            sx={{
                              mt: '0px !important',
                              '& .MuiSvgIcon-root, .MuiSvgIcon-fontSizeMedium, .MuiSelect-icon, .MuiSelect-iconStandard':
                                {
                                  display:
                                    role.roles?.length === 0
                                      ? 'none'
                                      : 'inline-block',
                                },
                            }}
                            disableUnderline={true}
                            multiple={true}
                            value={role.roles}
                            variant="standard"
                            onChange={(event) => {
                              const newRoles = event.target.value as RoleType[]
                              setRoles((prevRoles) =>
                                ensureArray(prevRoles).map((r, i) =>
                                  i === index
                                    ? {
                                        ...r,
                                        roles: newRoles,
                                      }
                                    : r
                                )
                              )
                            }}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip
                                    clickable={true}
                                    key={value}
                                    size="small"
                                    variant="outlined"
                                    label={
                                      possibleRoles.find((r) => r.key === value)
                                        ?.label
                                    }
                                    deleteIcon={
                                      <RiCloseLine
                                        size={16}
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                    onDelete={() => {
                                      setRoles(
                                        (prevRoles) =>
                                          prevRoles?.map((r, i) =>
                                            i === index
                                              ? {
                                                  ...r,
                                                  roles: r.roles.filter(
                                                    (role) => role !== value
                                                  ),
                                                }
                                              : r
                                          ) || []
                                      )
                                    }}
                                    sx={{
                                      mr: '8px',
                                      display: possibleRoles.find(
                                        (r) => r.key === value
                                      )?.showInChooser
                                        ? 'flex'
                                        : 'none',
                                      borderColor: (theme) =>
                                        theme.palette.action.disabled,

                                      '& .MuiChip-label': {
                                        fontWeight: 500,
                                        letterSpacing: '-0.2px',
                                        fontSize: '11px',
                                        pl: '10px',
                                        color: (theme) =>
                                          theme.palette.text.secondary,
                                      },
                                    }}
                                  />
                                ))}
                              </Box>
                            )}
                          >
                            {possibleRoles.map(
                              ({ key, label, showInChooser }) => (
                                <MenuItem
                                  key={key}
                                  value={key}
                                  sx={{
                                    display: showInChooser ? 'flex' : 'none',
                                  }}
                                >
                                  <Checkbox
                                    checked={role.roles?.indexOf(key) > -1}
                                  />
                                  <ListItemText primary={label} />
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                        {role.roles?.length === 0 && (
                          <Tooltip
                            title="Remove contact"
                            arrow={true}
                          >
                            <IconButton
                              sx={{ px: '3px' }}
                              onClick={async () => {
                                try {
                                  if (roles?.length < 8) {
                                    await confirm({
                                      title: 'Remove person from opportunity',
                                      description:
                                        'Are you sure you want to remove this person from the opportunity?',
                                    })
                                  }
                                  setRoles(
                                    (prevRoles) =>
                                      prevRoles?.filter(
                                        (_, i) => i !== index
                                      ) || []
                                  )
                                } catch (e) {
                                  logger.warn(e)
                                }
                              }}
                            >
                              <RiCloseLine size={18} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Row>
                    </Row>
                  </Box>
                )
            )}
        </Box>
      )}
      <Dialog
        open={showAddPerson}
        onClose={() => setShowAddPerson(false)}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <ObjectFinder
            objectTypes={[NativeObjectTypes.Person]}
            placeholder="Add person to opportunity"
            onSelect={(person) => {
              const email = person.objectId
              const defaultRoles =
                roles?.length === 0 ? ['PRIMARY_CONTACT'] : ['CHAMPION']
              setRoles((prevRoles) => [
                ...ensureArray(prevRoles),
                { personEmail: email, roles: defaultRoles },
              ])
              setShowAddPerson(false)
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default OpportunityRolesList
