import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'

import {
  Box,
  Button,
  IconButton,
  List,
  ListItemButton,
  TextField,
  Typography,
} from '@mui/material'
import {
  RiBuilding2Line,
  RiCloseLine,
  RiLineChartLine,
  RiMoneyDollarCircleLine,
  RiStickyNoteLine,
  RiUser6Line,
} from '@remixicon/react'
import dayjs from 'dayjs'
import Fuse from 'fuse.js'
import { debounce } from 'lodash'

import { useMutation, useQuery } from '@redwoodjs/web'

import {
  extractEmailDomain,
  NativeObjectFormatters,
  uniqueDomainsFromEmails,
} from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import ObjectAvatar from '../ObjectAvatar/ObjectAvatar'
import Row from '../Row/Row'

const GET_PIPELINES_FOR_SEARCH = gql`
  query GetPipelinesForSearch($workspaceId: String!) {
    pipelines(workspaceId: $workspaceId) {
      id
      title
      domains
      createdAt
      stages {
        id
        title
        opportunities {
          id
          title
          domain
          roles {
            personEmail
            roles
          }
        }
      }
    }
  }
`

//coreCompaniesByDomains(domains: [String!]!): [CoreCompany!]! @requireAuth
const GET_CORE_COMPANIES_FOR_SEARCH = gql`
  query GetCoreCompaniesForSearch($domains: [String!]!) {
    coreCompaniesByDomains(domains: $domains) {
      id
      name
      domain
      description
      photoSquare
      colorVibrant
      colorLightVibrant
      colorDarkVibrant
      colorDarkMuted
      colorMuted
    }
  }
`

const GET_PAGES_FOR_SEARCH = gql`
  query GetPagesForSearch($workspaceId: String!) {
    pages(workspaceId: $workspaceId) {
      id
      title
      emoji
      domains
      people
    }
  }
`

type SearchResult = {
  objectType: string
  objectId: string
  label: string
  description?: string
  photoUrl?: string
  properties: any
  domains?: string[]
  icon?: React.ReactNode
  emoji?: string
  colors?: {
    vibrant: string
    lightVibrant: string
    darkVibrant: string
    darkMuted: string
    muted: string
  }
}

const INIT_PERSON_FROM_SEARCH = gql`
  mutation InitPersonFromSearch(
    $email: String!
    $workspaceId: String!
    $source: String!
    $firstName: String
    $lastName: String
    $userId: String
    $workAccountUuid: String
  ) {
    initPersonAsync(
      email: $email
      workspaceId: $workspaceId
      source: $source
      firstName: $firstName
      lastName: $lastName
      userId: $userId
      workAccountUuid: $workAccountUuid
    )
  }
`

const getObjectTypeIcon = (objectType: string) => {
  const iconSize = 20
  switch (objectType) {
    case 'native_contact':
      return <RiUser6Line size={iconSize} />
    case 'native_organization':
      return <RiBuilding2Line size={iconSize} />
    case 'native_pipeline':
      return <RiLineChartLine size={iconSize} />
    case 'native_opportunity':
      return <RiMoneyDollarCircleLine size={iconSize} />
    case 'page':
      return <RiStickyNoteLine size={iconSize} />
    default:
      return <></>
  }
}

const defaultTextFieldSx = { px: 2, mx: 1 }
const defaultInputSx = {
  fontWeight: 600,
  fontSize: '1.1rem',
}

const ObjectFinder = ({
  onSelect,
  onChangeQuery = (query) => {},
  sx = {},
  textFieldSx = defaultTextFieldSx,
  inputSx = defaultInputSx,
  placeholder = 'Search for people, organizations, pipelines ...',
  objectTypes = [],
  freeSolo = false,
  distance = 100,
}) => {
  const { selectedWorkspace: workspaceId, workspacePeople } =
    useContext(DayContext)

  const [query, setQuery] = useState<string>('')
  const [currentSearchResults, setCurrentSearchResults] = useState<
    SearchResult[]
  >([])
  const [isValidFreeSoloInput, setIsValidFreeSoloInput] =
    useState<boolean>(false)

  const { data: pipelinesData } = useQuery(GET_PIPELINES_FOR_SEARCH, {
    variables: {
      workspaceId,
    },
    skip: !workspaceId,
  })

  const { data: pagesData } = useQuery(GET_PAGES_FOR_SEARCH, {
    variables: {
      workspaceId,
    },
    skip: !workspaceId,
  })

  const [upsertPerson] = useMutation(INIT_PERSON_FROM_SEARCH)

  const domains = useMemo(() => {
    if (workspacePeople.length > 0) {
      const emails = workspacePeople.map((person) => person?.email)
      return uniqueDomainsFromEmails({ emails })
    }
    return []
  }, [workspacePeople])

  const { data: coreCompaniesData } = useQuery(GET_CORE_COMPANIES_FOR_SEARCH, {
    variables: {
      domains,
    },
    skip: domains.length === 0,
  })

  const possibleSearchResults = useMemo(() => {
    const allObjects = []

    if (
      pipelinesData?.pipelines?.length > 0 &&
      (objectTypes.includes(NativeObjectTypes.Pipeline) ||
        objectTypes.length === 0)
    ) {
      for (const pipeline of pipelinesData.pipelines) {
        const pipelineResult: SearchResult = {
          objectType: 'native_pipeline',
          objectId: pipeline.id,
          label: pipeline.title,
          description: `Pipeline with ${pipeline.opportunityCount} opportunities`,
          photoUrl: null,
          properties: { ...pipeline },
        }
        allObjects.push(pipelineResult)
        for (const stage of pipeline.stages) {
          for (const opportunity of stage.opportunities) {
            const opportunityResult: SearchResult = {
              objectType: 'native_opportunity',
              objectId: opportunity.id,
              label: `Opportunity: ${opportunity.title}`,
              description: `"${pipeline.title}" pipeline, in the "${stage.name}" stage`,
              properties: { ...opportunity },
            }

            allObjects.push(opportunityResult)
          }
        }
      }
    } else {
      logger.dev('no pipelines')
    }

    if (
      coreCompaniesData?.coreCompaniesByDomains?.length > 0 &&
      (objectTypes.includes(NativeObjectTypes.Organization) ||
        objectTypes.length === 0)
    ) {
      for (const coreCompany of coreCompaniesData.coreCompaniesByDomains) {
        const coreCompanyResult: SearchResult = {
          objectType: 'native_organization',
          objectId: coreCompany.domain,
          label: NativeObjectFormatters[NativeObjectTypes.Organization].label({
            properties: coreCompany,
          }),
          description:
            NativeObjectFormatters[NativeObjectTypes.Organization].description({
              properties: coreCompany,
            }) ||
            NativeObjectFormatters[NativeObjectTypes.Organization].displayId({
              properties: coreCompany,
            }),
          photoUrl: coreCompany.photoSquare,
          colors: {
            vibrant: coreCompany.colorVibrant,
            lightVibrant: coreCompany.colorLightVibrant,
            darkVibrant: coreCompany.colorDarkVibrant,
            darkMuted: coreCompany.colorDarkMuted,
            muted: coreCompany.colorMuted,
          },
          properties: { ...coreCompany },
        }
        allObjects.push(coreCompanyResult)
      }
    }

    if (
      workspacePeople?.length > 0 &&
      (objectTypes.includes(NativeObjectTypes.Contact) ||
        objectTypes.length === 0)
    ) {
      for (const person of workspacePeople) {
        const displayId = person.email

        const label = person.fullName

        const fallbackDisplayId = label != person.email ? displayId : null

        const personResult: SearchResult = {
          objectType: 'native_contact',
          objectId: person.email,
          label,
          description: person.currentJobTitle || fallbackDisplayId,
          photoUrl: person.photoUrl,
          domains: [extractEmailDomain(person.email)],
          properties: { ...person },
        }
        allObjects.push(personResult)
      }
    }

    if (
      pagesData?.pages?.length > 0 &&
      (objectTypes.includes(NativeObjectTypes.Page) || objectTypes.length === 0)
    ) {
      for (const page of pagesData.pages) {
        const pageResult: SearchResult = {
          objectType: 'page',
          objectId: page.id,
          label: page.title,
          domains: page.domains,
          description: `Page created ${dayjs(page.createdAt).format(
            'MMM D, YY'
          )}`,
          emoji: page.emoji,
          properties: { ...page },
        }
        allObjects.push(pageResult)
      }
    }

    return allObjects
  }, [
    pipelinesData,
    coreCompaniesData,
    pagesData,
    objectTypes,
    workspacePeople,
  ])

  const searchOptions = useMemo(() => {
    return {
      keys: ['label', 'objectId', 'description', 'domains'],
      includeScore: true,
      includeMatches: true,
      threshold: 0.2,
      distance,
    }
  }, [distance])

  const fuse = useMemo(() => {
    const index = new Fuse(possibleSearchResults, searchOptions)
    return index
  }, [possibleSearchResults, searchOptions])

  // Memoize the search function
  const searchForObjects = useCallback(
    (query) => {
      if (query.length > 0) {
        const results = fuse.search(query)

        setCurrentSearchResults(results.map((result) => result.item))
        if (freeSolo) {
          let bestMatch = 0
          for (const result of results) {
            if (result.score > 0.1) {
              bestMatch = result.score
            }
          }

          // Check if the query is a valid email or website domain
          const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(query)
          const isValidDomain = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(
            query
          )

          const suspectedMatch = bestMatch > 0.1

          setIsValidFreeSoloInput(
            !suspectedMatch && (isValidEmail || isValidDomain)
          )
        }
      } else {
        setCurrentSearchResults([])
        setIsValidFreeSoloInput(false)
      }
    },
    [fuse, freeSolo]
  )

  const debouncedSearchForObjects = useMemo(
    () =>
      debounce((q) => {
        searchForObjects(q)
      }, 250),
    [searchForObjects]
  )

  const componentRef = useRef(null)

  useEffect(() => {
    onChangeQuery(query)
    debouncedSearchForObjects(query)
    // Cleanup function to cancel debounce on component unmount or query change
    return () => debouncedSearchForObjects.cancel()
  }, [query, debouncedSearchForObjects])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setQuery('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Box
      sx={sx}
      className={query.length > 0 ? 'has-query' : ''}
      ref={componentRef}
    >
      <TextField
        variant="standard"
        placeholder={placeholder}
        value={query}
        onChange={(e) => {
          if (e?.nativeEvent?.inputType === 'insertFromPaste') {
            // Clean up pasted content
            const pastedValue = e.target.value.trim().toLowerCase()
            logger.dev({ pastedValue })
            // Remove common prefixes
            const cleanedValue = pastedValue
              .replace(/^(https?:\/\/)?(www\.)?/, '')
              .replace(/\/.*$/, '') // Remove anything after the domain
              .toLowerCase() // Ensure the domain is lowercase
            logger.dev({ cleanedValue })

            setQuery(cleanedValue)
          } else {
            // For regular typing, just update as normal
            setQuery(e.target.value)
          }
        }}
        fullWidth={true}
        autoFocus={true}
        InputProps={{
          disableUnderline: true,
          sx: inputSx,
          endAdornment: query?.length > 0 && (
            <Row gap={1}>
              {freeSolo && isValidFreeSoloInput && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    if (query.includes('@')) {
                      upsertPerson({
                        variables: {
                          email: query,
                          workspaceId,
                          source: 'manual',
                        },
                      })
                    }
                    onSelect({
                      objectId: query,
                      objectType: query.includes('@')
                        ? NativeObjectTypes.Contact
                        : NativeObjectTypes.Organization,
                    })
                  }}
                >
                  Add
                </Button>
              )}
              <IconButton onClick={() => setQuery('')}>
                <RiCloseLine />
              </IconButton>
            </Row>
          ),
        }}
        sx={textFieldSx}
      />
      {currentSearchResults.length > 0 && (
        <List
          className="object-finder-results"
          sx={{ px: 1, overflowY: 'auto' }}
        >
          {currentSearchResults.map((result, index) => (
            <ListItemButton
              key={`searchResult_${result.objectId}_${index}`}
              onClick={() => onSelect(result)}
              sx={{ width: '100%', borderRadius: '4px' }}
            >
              <Row
                sx={{
                  justifyContent: 'space-between',
                  width: 'calc(100%)',
                }}
              >
                <Row
                  sx={{
                    flexShrink: 1,
                    width: 'calc(100% - 24px)',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {result.icon || result.emoji || (
                    <ObjectAvatar
                      crmObject={{
                        objectType: result.objectType,
                        objectId: result.objectId,
                        properties: {
                          photoUrl: result.photoUrl,
                          ...result.properties,
                        },
                      }}
                      size={36}
                    />
                  )}
                  <Box
                    sx={{
                      px: 2,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '0.9rem',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {result.label}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '0.7rem',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {result.description}
                    </Typography>
                  </Box>
                </Row>
                <Box
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    width: '20px',
                  }}
                >
                  {getObjectTypeIcon(result.objectType)}
                </Box>
              </Row>
            </ListItemButton>
          ))}
        </List>
      )}
    </Box>
  )
}

export default ObjectFinder
