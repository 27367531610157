import { useContext, useMemo, useState } from 'react'

import ReactJson from '@microlink/react-json-view'
import {
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  RiCloseLine,
  RiColorFilterLine,
  RiEditLine,
  RiRefreshLine,
  RiShareForwardLine,
  RiStickyNoteAddLine,
} from '@remixicon/react'
import toast from 'react-hot-toast'
import { v4 as uuid } from 'uuid'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import OrganizationActions from 'src/components/OrganizationActions/OrganizationActions'
import OrganizationPageCreateInstructions from 'src/components/OrganizationPageCreateInstructions/OrganizationPageCreateInstructions'
import OrganizationPeople from 'src/components/OrganizationPeople/OrganizationPeople'
import OrganizationOneSentence from 'src/components/Organizations/OrganizationOneSentence/OrganizationOneSentence'
import OrganizationQuotes from 'src/components/Organizations/OrganizationQuotes/OrganizationQuotes'
import OrganizationSocialLinks from 'src/components/Organizations/OrganizationSocialLinks/OrganizationSocialLinks'
import OrganizationTile from 'src/components/Organizations/OrganizationTile/OrganizationTile'
import OrganizationTimeline from 'src/components/Organizations/OrganizationTimeline/OrganizationTimeline'
import OpportunityDomainChip from 'src/components/Pipeline/OpportunityDomainChip/OpportunityDomainChip'
import WorkspaceUserContextDialog from 'src/components/WorkspaceUserContextDialog/WorkspaceUserContextDialog'
import { DayContext } from 'src/lib/dayContext'
import { isInternalUser, ungatedForCrm3 } from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import { mergeObjects } from 'src/lib/objects'

import Row from '../../Row/Row'
import { actionChipStyle, sidebarBodyTextStyle } from '../Sidebar'
import SidebarSlack from '../SidebarSlack/SidebarSlack'

const GET_ORGANIZATION_FOR_ORG_SIDEBAR = gql`
  query GetOrganizationForOrgSidebar($workspaceId: String!, $orgId: String!) {
    workspaceOrganization(workspaceId: $workspaceId, domain: $orgId) {
      id
      name
      domain
      description
      aiDescription
      people {
        fullName
        email
        currentJobTitle
        photoUrl
      }
      roles {
        name
        email
        role
      }
      opportunities {
        id
        workspaceId
        title
        pipelineId
        pipelineTitle
        stage
        updatedAt
      }
      pages {
        id
        title
      }
      photos {
        square
      }
      relationship {
        upcomingEvents
        quotes {
          personEmail
          text
          meetingId
        }
        oneSentenceSummary
        proofOfPayment
        sensitiveToWhom
        sensitiveReasoning
        warmth
        origin
      }
      links {
        facebook
        x
        instagram
        linkedIn
        website
        websiteResolvedUrl
      }
      lifecycle {
        pipelineType
        stageType
      }
    }
  }
`

const MIGRATE_CORE_COMPANY_ASYNC_SIDEBAR = gql`
  mutation MigrateCoreCompanyAsyncMutation($domain: String!) {
    migrateCoreCompanyAsync(domain: $domain)
  }
`

const SidebarLayoutOrganization = ({ domain, setOpen }) => {
  const { currentUser } = useAuth()
  const { selectedWorkspace, workAccounts, orgsByDomain } =
    useContext(DayContext)

  const [noteCreateOrganizationId, setNoteCreateOrganizationId] = useState(null)
  const [forceRefreshKey, setForceRefreshKey] = useState(uuid())
  const [adminDebug, setAdminDebug] = useState(
    process.env.HOST.includes('localhost')
  )

  const { data: orgData, loading: organizationLoading } = useQuery(
    GET_ORGANIZATION_FOR_ORG_SIDEBAR,
    {
      variables: {
        workspaceId: selectedWorkspace,
        orgId: domain,
      },
      skip: !domain || !selectedWorkspace,
      onCompleted: ({ workspaceOrganization }) => {
        logger.dev({ workspaceOrganization })
      },
    }
  )

  const [migrateCoreCompanyAsync] = useMutation(
    MIGRATE_CORE_COMPANY_ASYNC_SIDEBAR
  )

  const handleMigrateCoreCompanyAsync = () => {
    toast.promise(migrateCoreCompanyAsync({ variables: { domain } }), {
      loading: 'Migrating organization...',
      success: 'Organization migrated successfully!',
      error: 'Failed to migrate organization',
    })
  }

  const organization = useMemo(
    () => mergeObjects(orgData?.workspaceOrganization, orgsByDomain[domain]),
    [orgData?.workspaceOrganization, orgsByDomain, domain]
  )

  return (
    <>
      <Row sx={{ justifyContent: 'space-between', pt: 2, px: 3, pb: '4px' }}>
        <Row gap={2}>
          <DomainAvatar
            domain={domain}
            size={52}
          />
          <Box sx={{ width: '410px', overflow: 'hidden' }}>
            <Row gap={1}>
              <Typography
                variant="h1"
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  flexShrink: 1,
                  fontSize: '1.5rem',
                }}
              >
                {organization?.name || organization?.domain || domain}
              </Typography>
              <OrganizationSocialLinks organization={organization} />
            </Row>
            <Typography>{organization?.domain || domain}</Typography>
          </Box>
        </Row>
        <Row gap={1}>
          <Button
            color="primary"
            variant="outlined"
            sx={{ px: 1, fontSize: '0.7rem', fontWeight: 500 }}
            onClick={() => {
              navigate(
                routes.objectDetail({
                  workspaceId: selectedWorkspace,
                  objectTypeSlug: 'organizations',
                  objectId: organization?.domain || domain,
                })
              )
              setOpen(false)
            }}
          >
            View more
          </Button>
          <IconButton onClick={() => setOpen(false)}>
            <RiCloseLine />
          </IconButton>
        </Row>
      </Row>

      <Row
        sx={{ mt: 1, px: 3 }}
        gap={1}
      >
        <Tooltip title={`Add note to ${organization?.name}`}>
          <Chip
            clickable={true}
            icon={<RiStickyNoteAddLine size={14} />}
            label="Note"
            variant="outlined"
            size="small"
            sx={{ ...actionChipStyle }}
            onClick={() => {
              setNoteCreateOrganizationId(organization?.domain || domain)
            }}
          />
        </Tooltip>
        {noteCreateOrganizationId && (
          <WorkspaceUserContextDialog
            onClose={() => {
              setNoteCreateOrganizationId(null)

              setTimeout(() => {
                setForceRefreshKey(uuid())
              }, 1000)
            }}
            title={`Add note to ${organization?.name}`}
            organizationId={noteCreateOrganizationId}
          />
        )}
        <Tooltip
          title="Copy link to clipboard"
          arrow={true}
        >
          <Chip
            clickable={true}
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.HOST}${routes.objectDetail({
                  workspaceId: selectedWorkspace,
                  objectTypeSlug: 'organizations',
                  objectId: organization?.domain || domain,
                })}`
              )
              toast.success('Link copied to clipboard!')
            }}
            size="small"
            sx={{ ...actionChipStyle }}
            label="Share"
            variant="outlined"
            icon={<RiShareForwardLine size={14} />}
          />
        </Tooltip>
        {ungatedForCrm3({
          selectedWorkspace,
          workAccounts,
        }) && <OrganizationPageCreateInstructions orgId={domain} />}
        {isInternalUser(currentUser) && (
          <Tooltip title="Debug organization">
            <IconButton onClick={() => setAdminDebug((prev) => !prev)}>
              <RiColorFilterLine size={14} />
            </IconButton>
          </Tooltip>
        )}
      </Row>

      {isInternalUser(currentUser) && adminDebug && (
        <Box sx={{ px: 3, mt: 3 }}>
          <Row
            gap={1}
            sx={{ mb: 2 }}
          >
            <Tooltip
              title="(Admin) Migrate organization"
              arrow={true}
            >
              <Chip
                clickable={true}
                onClick={handleMigrateCoreCompanyAsync}
                size="small"
                sx={{ ...actionChipStyle }}
                label="Migrate"
                variant="outlined"
                icon={<RiRefreshLine size={14} />}
              />
            </Tooltip>
            <Tooltip
              title="(Admin) Manage organization"
              arrow={true}
            >
              <Chip
                clickable={true}
                onClick={() => {
                  navigate(
                    routes.editCoreCompany({
                      domain,
                    })
                  )
                }}
                size="small"
                sx={{ ...actionChipStyle }}
                label="Edit"
                variant="outlined"
                icon={<RiEditLine size={14} />}
              />
            </Tooltip>
          </Row>
          <ReactJson
            src={organization}
            collapsed={1}
          />
        </Box>
      )}
      <Box sx={{ px: 3, height: 'calc(100vh - 128px)', overflowY: 'auto' }}>
        <Box sx={{ pt: 2, pb: 3 }}>
          <Typography sx={sidebarBodyTextStyle}>
            {organization?.aiDescription || organization?.description}
          </Typography>
        </Box>
        <Box
          sx={{
            pt: 2,
            mb: 5,
          }}
        >
          <Row sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h2">Opportunities</Typography>
            <OpportunityDomainChip
              organization={organization}
              openSidebar={true}
            />
          </Row>
        </Box>
        <Box>
          {organization?.relationship?.oneSentenceSummary && (
            <Box sx={{}}>
              <OrganizationOneSentence org={organization} />
            </Box>
          )}

          <SidebarSlack
            orgId={organization?.domain || domain}
            sx={{ mt: '64px' }}
          />
          {organization?.relationship?.quotes && (
            <Box sx={{ mt: 3 }}>
              <OrganizationQuotes org={organization} />
            </Box>
          )}
          <Box sx={{ mt: 3 }}>
            <OrganizationActions orgId={organization?.domain || domain} />
          </Box>
          {organization?.roles?.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <OrganizationPeople organization={organization} />
            </Box>
          )}
          {organization?.quotes?.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <OrganizationQuotes org={organization} />
            </Box>
          )}
          {false && (
            <Box sx={{ mt: 3, mb: 6 }}>
              <Typography variant="h2">About</Typography>
              <Box
                sx={{ cursor: 'pointer', mt: 3 }}
                onClick={() => {
                  navigate(
                    routes.objectDetail({
                      objectId: organization?.domain,
                      workspaceId: selectedWorkspace,
                      objectTypeSlug: 'organizations',
                    })
                  )
                }}
              >
                <OrganizationTile
                  domain={organization?.domain}
                  openSidebar={false}
                />
              </Box>
            </Box>
          )}
          <Box sx={{ mt: 3 }}>
            <OrganizationTimeline
              org={organization}
              orgId={organization?.domain || domain}
              showHeader={true}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SidebarLayoutOrganization
