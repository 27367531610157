import { useContext } from 'react'

import { Box, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'

import SidebarActions from '../Sidebar/SidebarActions/SidebarActions'

const GET_ACTIONS_FOR_ORGANIZATION_SIDEBAR = gql`
  query GetActionsForOrganizationSidebar(
    $organizationId: String!
    $workspaceId: String!
  ) {
    actionsForOrganization(
      organizationId: $organizationId
      workspaceId: $workspaceId
    ) {
      id
      title
      people
      organizations
      status {
        id
        label
        updatedAt
      }
      owner {
        id
        email
      }
      channel {
        id
        label
        accountId
        type
      }
      createdAt
    }
  }
`

const OrganizationActions = ({ orgId }: { orgId: string }) => {
  const { selectedWorkspace } = useContext(DayContext)
  const { data, refetch, loading } = useQuery(
    GET_ACTIONS_FOR_ORGANIZATION_SIDEBAR,
    {
      variables: { organizationId: orgId, workspaceId: selectedWorkspace },
      skip: !selectedWorkspace || !orgId,
    }
  )
  const actions = data?.actionsForOrganization || []
  return data && actions.length > 0 ? (
    <Box sx={{ mt: 0 }}>
      <Typography variant="h2">Actions</Typography>
      <SidebarActions
        actions={actions}
        onUpdate={refetch}
      />
    </Box>
  ) : null
}

export default OrganizationActions
