import { useContext, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  RiArrowLeftRightLine,
  RiCheckLine,
  RiFileCopyLine,
  RiMoreLine,
  RiSubtractLine,
  RiTimeLine,
  RiUserSharedLine,
} from '@remixicon/react'
import { Dayjs } from 'dayjs'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'
import { Action, WorkspaceMember } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import ActionDialog from 'src/components/Actions/ActionDialog/ActionDialog'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'

import ContactTile from '../../ContactTile/ContactTile'
import Row from '../../Row/Row'
import WorkspaceMemberSelect from '../../WorkspaceMemberSelect/WorkspaceMemberSelect'

const DELETE_ACTION = gql`
  mutation DeleteAction($id: String!, $workspaceId: String!) {
    deleteAction(id: $id, workspaceId: $workspaceId)
  }
`

const COMPLETE_ACTION = gql`
  mutation CompleteAction($id: String!, $workspaceId: String!) {
    completeAction(id: $id, workspaceId: $workspaceId)
  }
`

const SNOOZE_ACTION = gql`
  mutation SnoozeAction(
    $id: String!
    $workspaceId: String!
    $snoozeUntil: DateTime!
  ) {
    snoozeAction(id: $id, workspaceId: $workspaceId, snoozeUntil: $snoozeUntil)
  }
`

const REASSIGN_ACTION = gql`
  mutation ReassignAction(
    $id: String!
    $workspaceId: String!
    $reassigneeEmail: String!
    $reassigneeId: String!
  ) {
    reassignAction(
      id: $id
      workspaceId: $workspaceId
      reassigneeEmail: $reassigneeEmail
      reassigneeId: $reassigneeId
    )
  }
`

const DEDUPLICATE_ACTION = gql`
  mutation DeduplicateAction($id: String!, $workspaceId: String!) {
    deduplicateActionAsync(workspaceId: $workspaceId, actionId: $id)
  }
`

const ActionActions = ({
  action,
  onUpdate,
  inFocus = false,
  onClose = () => {},
  showButtons = false,
  size = 24,
}: {
  action: Action
  onUpdate: (args?: {
    id?: string
    status?: string
    owner?: { id: string; email: string }
  }) => void
  inFocus?: boolean
  onClose?: () => void
  showButtons?: boolean
  size?: number
}) => {
  const confirm = useConfirm()
  const { selectedWorkspace: workspaceId } = useContext(DayContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const [reassignOpen, setReassignOpen] = useState(false)
  const [snoozeOpen, setSnoozeOpen] = useState(false)
  const [snoozeValue, setSnoozeValue] = useState<Dayjs | null>(null)
  const [reassignee, setReassignee] = useState<WorkspaceMember | null>(null)

  const [deleteAction] = useMutation(DELETE_ACTION)
  const [completeAction] = useMutation(COMPLETE_ACTION)
  const [reassignAction] = useMutation(REASSIGN_ACTION)
  const [snoozeAction] = useMutation(SNOOZE_ACTION)
  const [deduplicateAction] = useMutation(DEDUPLICATE_ACTION)

  const handleDelete = async () => {
    try {
      await confirm({
        title: 'Delete Action?',
        description: 'Are you sure you want to delete this action?',
      })
      toast.promise(
        deleteAction({
          variables: { id: action.id, workspaceId },
        }),
        {
          loading: 'Deleting action...',
          success: () => {
            onUpdate({ id: action.id, status: 'DELETED' })
            return 'Action deleted'
          },
          error: 'Error deleting action',
        }
      )
    } catch (error) {
      logger.warn(`Error deleting action: ${error}`)
    }
  }

  const handleComplete = () => {
    toast.promise(
      completeAction({
        variables: { id: action.id, workspaceId },
      }),
      {
        loading: 'Marking as complete...',
        success: () => {
          onUpdate({ id: action.id, status: 'COMPLETED' })
          return 'Action completed'
        },
        error: 'Error completing action',
      }
    )
  }

  const handleReassign = () => {
    toast.promise(
      reassignAction({
        variables: {
          id: action.id,
          workspaceId,
          reassigneeEmail: reassignee.email,
          reassigneeId: reassignee.id,
        },
      }),
      {
        loading: 'Reassigning action...',
        success: () => {
          onUpdate({
            id: action.id,
            owner: { id: reassignee.id, email: reassignee.email },
          })
          setReassignOpen(false)
          setReassignee(null)
          return 'Action reassigned'
        },
        error: 'Error reassigning action',
      }
    )
  }

  const handleSnooze = () => {
    toast.promise(
      snoozeAction({
        variables: {
          id: action.id,
          workspaceId,
          snoozeUntil: snoozeValue?.toDate(),
        },
      }),
      {
        loading: 'Snoozing action...',
        success: () => {
          onUpdate({ id: action.id, status: 'SNOOZED' })
          setSnoozeOpen(false)
          return 'Action snoozed'
        },
        error: 'Error snoozing action',
      }
    )
  }

  const handleDeduplicate = () => {
    toast.promise(
      deduplicateAction({
        variables: { id: action.id, workspaceId },
      }),
      {
        loading: 'Researching possible duplicates...',
        success: () => {
          onUpdate({ id: action.id, status: 'NONE' })
          return 'Thank you for the heads up! Day.ai will search for duplicates.'
        },
        error: 'Error deduplicating action',
      }
    )
  }

  return (
    <>
      {showButtons ? (
        <Row
          gap={1}
          sx={{
            '& .MuiButtonBase-root': {
              p: '6px',
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 2,
              '&:hover': {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.secondary.contrastText,
              },
            },
          }}
        >
          <Tooltip
            title="Mark complete"
            arrow={true}
          >
            <IconButton onClick={handleComplete}>
              <RiCheckLine size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Snooze action"
            arrow={true}
          >
            <IconButton onClick={() => setSnoozeOpen(true)}>
              <RiTimeLine size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Reassign"
            arrow={true}
          >
            <IconButton onClick={() => setReassignOpen(true)}>
              <RiUserSharedLine size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Ask AI to deduplicate"
            arrow={true}
          >
            <IconButton onClick={handleDeduplicate}>
              <RiFileCopyLine size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Dismiss"
            arrow={true}
          >
            <IconButton onClick={handleDelete}>
              <RiSubtractLine size={18} />
            </IconButton>
          </Tooltip>
        </Row>
      ) : (
        <Row sx={{ height: `${size}px` }}>
          <IconButton
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{ p: '3px' }}
          >
            <RiMoreLine size={size} />
          </IconButton>
          <Menu
            open={menuOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            sx={{
              p: 0,
              '& .MuiTypography-root': {
                fontSize: '12px',
                fontWeight: 500,
                letterSpacing: '-0.3px',
                color: 'text.secondary',
              },
            }}
          >
            <MenuItem
              onClick={handleComplete}
              sx={{ p: 0, pr: 3 }}
            >
              <Row>
                <IconButton>
                  <RiCheckLine size={16} />
                </IconButton>
                <Typography>Complete</Typography>
              </Row>
            </MenuItem>
            <MenuItem
              onClick={() => setReassignOpen(true)}
              sx={{ p: 0, pr: 3 }}
            >
              <Row>
                <IconButton>
                  <RiArrowLeftRightLine size={16} />
                </IconButton>
                <Typography>Reassign</Typography>
              </Row>
            </MenuItem>
            <MenuItem
              onClick={() => setSnoozeOpen(true)}
              sx={{ p: 0, pr: 3 }}
            >
              <Row>
                <IconButton>
                  <RiTimeLine size={16} />
                </IconButton>
                <Typography>Snooze</Typography>
              </Row>
            </MenuItem>
            <MenuItem
              onClick={handleDelete}
              sx={{ p: 0, pr: 3 }}
            >
              <Row>
                <IconButton>
                  <RiSubtractLine size={16} />
                </IconButton>
                <Typography>Dismiss</Typography>
              </Row>
            </MenuItem>
            <MenuItem
              onClick={handleDeduplicate}
              sx={{ p: 0, pr: 3 }}
            >
              <Row>
                <IconButton>
                  <RiFileCopyLine size={16} />
                </IconButton>
                <Typography>Ask AI to deduplicate</Typography>
              </Row>
            </MenuItem>
          </Menu>
        </Row>
      )}
      {inFocus && (
        <ActionDialog
          action={action}
          onClose={onClose}
          onUpdate={onClose}
        />
      )}
      <Dialog
        open={reassignOpen}
        onClose={() => {
          setReassignee(null)
          setReassignOpen(false)
        }}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>Reassign Action</DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              p: 2,
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 10,
              fontWeight: 500,
              letterSpacing: '-0.3px',
              mb: 3,
            }}
          >
            {`"${action.title}"`}
          </Typography>
          <Box sx={{ pt: 1 }}>
            {reassignee ? (
              <ContactTile
                email={reassignee.email}
                showSidebar={false}
              />
            ) : (
              <WorkspaceMemberSelect
                onSelect={(members) => {
                  setReassignee(members?.[0])
                }}
                value={[]}
                exclude={[action.owner?.id, action.owner?.email]}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!reassignee || reassignee?.id === action.owner?.id}
            onClick={handleReassign}
            size="large"
            variant={reassignee ? 'contained' : 'outlined'}
            disableElevation={true}
            fullWidth={true}
          >
            {reassignee?.id === action.owner?.id
              ? 'Already assigned'
              : 'Reassign'}
          </Button>
        </DialogActions>
      </Dialog>
      {snoozeOpen && (
        <Dialog
          open={snoozeOpen}
          onClose={() => setSnoozeOpen(false)}
        >
          <DialogTitle>Snooze until ...</DialogTitle>
          <DialogContent sx={{ height: '324px', overflow: 'hidden' }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              dateLibInstance={dayjs}
            >
              <DateCalendar
                value={snoozeValue}
                onChange={(newValue) => setSnoozeValue(newValue)}
                timezone="system"
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions sx={{ pt: 0 }}>
            <Button
              variant="outlined"
              fullWidth={true}
              onClick={handleSnooze}
            >
              Snooze
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default ActionActions
