import React, {
  useState,
  createContext,
  ReactNode,
  useMemo,
  useEffect,
  useCallback,
} from 'react'

import type {
  GlobalWorkAccountWorkspaceConnectionsQuery,
  Organization,
  Person,
} from 'types/graphql'
import { WorkAccountWorkspaceConnection, Workspace } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useRouteName } from '@redwoodjs/router/dist/useRouteName'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { getStoredPipelineId } from 'src/pages/OpportunitiesPage/OpportunitiesPage'
import { onboardingStatus } from 'src/pages/WelcomePage/WelcomePage'

import { extractEmailDomain } from './contactFormatting'
import { isObjPropOnly, ungatedForMeetingRecordingCache } from './gates'
import { logger } from './logger'
import { mergeObjects } from './objects'

const USER_CORE_CONTACT_QUERY = gql`
  query UserCoreContactQuery($email: String!) {
    coreContact(email: $email) {
      id
      email
      username
      firstName
      lastName
      title
      domain
      industry
      photo
      department
      description
      unsubscribed
      linkedInUrl
      linkInBioJson
      timezone
      travelTimezone
    }
  }
`

const WORK_ACCOUNTS_QUERY = gql`
  query DayContextFindWorkAccountByOwnerEmail($ownerEmail: String!) {
    workAccounts(ownerEmail: $ownerEmail) {
      id
      createdAt
      uuid
      email
      name
      tokenError
      scopes
      crm3WorkspaceId
      googleSyncStatuses {
        type
        status
        reason
        statusChangedAt
        lastSeenSyncToken
        lastSyncedAt
      }
      calendarAutoRecordSettings {
        mode
        availableModes
      }
      notificationSubscriptionOverrides {
        notificationKey
        enabled
      }
      workspaces {
        id
        name
        domains {
          domain
        }
      }
    }
  }
`

const FETCH_WORKSPACES_AND_ROLES = gql`
  query FetchWorkspacesAndRoles($userEmail: String!) {
    workspaces(userEmail: $userEmail) {
      id
      name
      status
      domains {
        domain
        autoInvite
        autoInviteRoleId
      }
      claimableDomains
      isDefault
      treeState
      members {
        id
        email
        status
        isDefaultOwner
        roleId
        coreContact {
          firstName
          lastName
          photo
          title
        }
      }
      roles {
        id
        name
        description
        permissions {
          type
          permission
          scope
        }
      }
    }
  }
`

const WORK_ACCOUNT_WORKSPACE_CONNECTIONS_QUERY = gql`
  query GlobalWorkAccountWorkspaceConnectionsQuery {
    workAccountWorkspaceConnections {
      workAccountUuid
      connectedWorkspaces {
        workspaceId
        sharingRules {
          category
          selector {
            type
            expression
          }
          authorization {
            type
            id
          }
        }
      }
    }
  }
`

const GET_ALL_WORKSPACE_CONTACTS_FOR_CONTEXT = gql`
  query GetAllContactsForUserForContext($workspaceId: String!) {
    getAllContactsForUserForWorkspace(workspaceId: $workspaceId) {
      objectType
      objectId
      properties
    }
  }
`

const MEETING_RECORDINGS_FOR_CONTEXT = gql`
  query MeetingRecordingsForContext(
    $workspaceId: String!
    $limit: Int!
    $page: Int!
  ) {
    workspaceMeetingRecordings(
      workspaceId: $workspaceId
      limit: $limit
      page: $page
    ) {
      pageInfo {
        limit
        page
        totalPages
      }
      recordings {
        id
        title
        summary {
          output
        }
        startedAt
        endedAt
        statusHistory {
          level
          status
          createdAt
          reason
          message
        }
        participants {
          email
        }
        clips {
          id
          startSeconds
          endSeconds
        }
        calendarEvents {
          id
          GoogleEvent {
            title
          }
        }
      }
    }
  }
`

const GET_SUGGESTED_EXCLUSIONS_CONTEXT = gql`
  query GetSuggestedExclusions($workspaceId: String!, $workAccountId: String!) {
    getSuggestedExclusions(
      workspaceId: $workspaceId
      workAccountId: $workAccountId
    ) {
      reason
      domain
      category
    }
  }
`

const GET_WORKSPACE_PEOPLE_CONTEXT = gql`
  query GetWorkspacePeople($workspaceId: String!) {
    workspacePeople(workspaceId: $workspaceId) {
      id
      fullName
      currentJobTitle
      photoUrl
      email
      linkedInUrl
      relationship {
        type
        oneSentence
      }
    }
  }
`

const GET_WORKSPACE_ORGANIZATIONS_1 = gql`
  query GetWorkspaceOrganizations($workspaceId: String!) {
    workspaceOrganizations(workspaceId: $workspaceId) {
      id
      name
      domain
      photos {
        square
      }
      lifecycle {
        pipelineType
        stageType
        stageNumber
      }
      roles {
        email
        role
      }
    }
  }
`

const GET_WORKSPACE_ORGANIZATIONS_2 = gql`
  query GetWorkspaceOrganizations2($workspaceId: String!) {
    workspaceOrganizations(workspaceId: $workspaceId) {
      id
      domain
      employeeCount
      annualRevenue
      funding
      colors {
        colorVibrant
        colorDarkVibrant
        colorLightVibrant
        colorMuted
        colorLightMuted
        colorDarkMuted
      }
      links {
        facebook
        linkedIn
        x
        instagram
        youtube
        website
      }
      about {
        aiDescription
        description
        industry
        selling
        employeesTo
        employeesFrom
      }
    }
  }
`

const GET_WORKSPACE_ORGANIZATIONS_3 = gql`
  query GetWorkspaceOrganizations3($workspaceId: String!) {
    workspaceOrganizations(workspaceId: $workspaceId) {
      id
      domain
      relationship {
        upcomingEvents
        quotes {
          personEmail
          text
          meetingId
        }
        oneSentenceSummary
        proofOfPayment
        sensitiveToWhom
        sensitiveReasoning
        warmth
        origin
      }
    }
  }
`

const GET_WORKSPACE_PIPELINE = gql`
  query GetWorkspacePipeline($workspaceId: String!, $id: String!) {
    pipeline(id: $id, workspaceId: $workspaceId) {
      id
      workspaceId
      title
      hasRevenue
      updatedAt
      opportunityTypes
      ownerEmails
      stages {
        id
        title
        entranceCriteria
        likelihoodToClose
        opportunities {
          id
          title
          ownerEmail
          ownerId
          expectedCloseDate
          expectedRevenue
          domain
          position
          status
          type
          roles {
            personEmail
            roles
          }
          goals {
            content
            source {
              sourceId
              sourceType
            }
          }
          impactOfChange {
            content
            source {
              sourceId
              sourceType
            }
          }
          budgetAndTimeline {
            content
            source {
              sourceId
              sourceType
            }
          }
          recommendedStage {
            expectedCloseDate
            expectedRevenue
            proofOfPayment
            readyToProgress
            reasoningforStage
            stageId
          }
          challenges {
            challenge
            solution
            source {
              sourceId
              sourceType
            }
          }
          risks {
            content
            source {
              sourceId
              sourceType
            }
          }
          competition {
            content
            source {
              sourceId
              sourceType
            }
          }
          decisionProcess {
            content
            source {
              sourceId
              sourceType
            }
          }
        }
        position
      }
    }
  }
`

interface DayContextProps {
  userCoreContact: any
  setUserCoreContact: (userCoreContact: any) => void
  refetchUserCoreContact: () => void
  workAccounts: any[]
  setWorkAccounts: (workAccounts: any[]) => void
  refetchWorkAccounts: () => void
  workspaces: Workspace[]
  refetchWorkspaces: () => Promise<any>
  sidebarObject: any
  setSidebarObject: (sidebarObject: any) => void
  workAccountWorkspaceConnections: WorkAccountWorkspaceConnection[]
  refetchWorkAccountWorkspaceConnections: () => void
  selectedWorkspace: string | null
  setSelectedWorkspace: (workspaceId: string) => void
  people: any[]
  refetchPeople: () => void
  loading: boolean
  internalDomains: string[]
  selectedWorkspaceLoading: boolean
  suggestedExclusions: any[]
  refetchSuggestedExclusions: () => void
  workspacePeople: Person[]
  workspaceOrganizations: Organization[]
  refetchWorkspaceOrganizations: () => void
  orgsByDomain: Record<string, Organization>
  peopleByEmail: Record<string, Person>
}

export const DayContext = createContext<DayContextProps>({
  userCoreContact: null,
  setUserCoreContact: () => {},
  refetchUserCoreContact: () => {},
  workAccounts: [],
  setWorkAccounts: () => {},
  refetchWorkAccounts: () => {},
  workspaces: [],
  refetchWorkspaces: async () => {},
  workAccountWorkspaceConnections: null,
  refetchWorkAccountWorkspaceConnections: () => {},
  sidebarObject: null,
  setSidebarObject: () => {},
  selectedWorkspace: null,
  setSelectedWorkspace: () => {},
  people: [],
  refetchPeople: () => {},
  loading: false,
  internalDomains: [],
  selectedWorkspaceLoading: true,
  suggestedExclusions: [],
  refetchSuggestedExclusions: () => {},
  workspacePeople: [],
  workspaceOrganizations: [],
  refetchWorkspaceOrganizations: () => {},
  orgsByDomain: {},
  peopleByEmail: {},
})

interface DayProviderProps {
  children: ReactNode
}

// LocalStorage will happily stringify null/undefined values
// and then hand them back to you as strings, which messes up
// truthy/falsey checks. Let's immediately convert them back
// before using them in business logic.
const parseLocalStorageValue = (value: string) => {
  if (value === 'null' || value === 'undefined') return null

  return value
}

export const DayProvider: React.FC<DayProviderProps> = ({ children }) => {
  const { currentUser } = useAuth()
  const route = useRouteName()
  const [sidebarObject, setSidebarObject] = useState<any>(null)
  const [userCoreContact, setUserCoreContact] = useState<any>(null)
  const [workAccounts, setWorkAccounts] = useState<any[]>([])
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [workAccountWorkspaceConnections, setWorkAccountWorkspaceConnections] =
    useState<WorkAccountWorkspaceConnection[]>([])
  const [people, setPeople] = useState<any[]>([])
  const [selectedWorkspace, setSelectedWorkspace] = useState<string | null>(
    null
  )
  const [selectedWorkspaceLoading, setSelectedWorkspaceLoading] = useState(true)
  const [meetingRecordings, setMeetingRecordings] = useState<any[]>([])
  const [meetingRecordingsPageInfo, setMeetingRecordingsPageInfo] =
    useState<any>(null)
  const [meetingRecordingsPage, setMeetingRecordingsPage] = useState<number>(1)

  const PRECACHE_MEETING_RECORDINGS = ungatedForMeetingRecordingCache.includes(
    currentUser?.email
  )

  const { refetch: refetchMeetingRecordings } = useQuery(
    MEETING_RECORDINGS_FOR_CONTEXT,
    {
      variables: {
        limit: 20,
        page: meetingRecordingsPage,
        workspaceId: selectedWorkspace,
      },
      onCompleted: (data) => {
        setMeetingRecordings((prev) => [
          ...prev,
          ...data.workspaceMeetingRecordings.recordings,
        ])
        setMeetingRecordingsPageInfo(data.workspaceMeetingRecordings.pageInfo)
        setMeetingRecordingsPage(
          data.workspaceMeetingRecordings.pageInfo.page + 1
        )
      },
      skip:
        !selectedWorkspace ||
        !PRECACHE_MEETING_RECORDINGS ||
        meetingRecordingsPageInfo?.totalPages < meetingRecordingsPage,
    }
  )

  const selectedPipelineId = useMemo(
    () => (selectedWorkspace ? getStoredPipelineId(selectedWorkspace) : null),
    [selectedWorkspace]
  )

  const { data: workspacePipelineData } = useQuery(GET_WORKSPACE_PIPELINE, {
    variables: { workspaceId: selectedWorkspace, id: selectedPipelineId },
    skip: !selectedWorkspace || !selectedPipelineId,
  })

  const { refetch: refetchPeople } = useQuery(
    GET_ALL_WORKSPACE_CONTACTS_FOR_CONTEXT,
    {
      variables: { workspaceId: selectedWorkspace },
      skip: !selectedWorkspace || isObjPropOnly(currentUser),
      onCompleted: (data) => {
        setPeople(data.getAllContactsForUserForWorkspace)
      },
    }
  )

  const {
    data: userCoreContactData,
    refetch: refetchUserCoreContact,
    loading: userCoreContactLoading,
  } = useQuery(USER_CORE_CONTACT_QUERY, {
    variables: { email: currentUser?.email },
    skip: !currentUser,
    onCompleted: (data) => {
      setUserCoreContact(data.coreContact)
    },
  })

  const {
    data: workAccountsData,
    refetch: refetchWorkAccounts,
    loading: workAccountsLoading,
  } = useQuery(WORK_ACCOUNTS_QUERY, {
    variables: {
      ownerEmail: currentUser?.email,
    },
    skip: !currentUser,
    onCompleted: (data) => {
      setWorkAccounts(data.workAccounts)
    },
  })

  const {
    data: workspacesData,
    refetch: refetchWorkspaces,
    loading: workspacesLoading,
  } = useQuery(FETCH_WORKSPACES_AND_ROLES, {
    variables: { userEmail: currentUser?.email },
    skip: !currentUser,
    onCompleted: (data) => {
      const workspacesFromQuery = data.workspaces || []

      setWorkspaces(workspacesFromQuery)
    },
  })

  const { data: workspacePeopleData, refetch: refetchWorkspacePeople } =
    useQuery(GET_WORKSPACE_PEOPLE_CONTEXT, {
      variables: { workspaceId: selectedWorkspace },
      skip: !selectedWorkspace,
    })

  const {
    data: workspaceOrganizationsData,
    refetch: refetchWorkspaceOrganizationsBasic,
  } = useQuery(GET_WORKSPACE_ORGANIZATIONS_1, {
    variables: { workspaceId: selectedWorkspace },
    skip: !selectedWorkspace,
  })

  const {
    data: workspaceOrganizationsData2,
    refetch: refetchWorkspaceOrganizations2,
  } = useQuery(GET_WORKSPACE_ORGANIZATIONS_2, {
    variables: { workspaceId: selectedWorkspace, more: true },
    skip: !selectedWorkspace,
  })

  const {
    data: workspaceOrganizationsData3,
    refetch: refetchWorkspaceOrganizations3,
  } = useQuery(GET_WORKSPACE_ORGANIZATIONS_3, {
    variables: { workspaceId: selectedWorkspace, more: true },
    skip: !selectedWorkspace,
  })

  const refetchWorkspaceOrganizations = useCallback(() => {
    refetchWorkspaceOrganizationsBasic()
    refetchWorkspaceOrganizations2()
    refetchWorkspaceOrganizations3()
  }, [
    refetchWorkspaceOrganizationsBasic,
    refetchWorkspaceOrganizations2,
    refetchWorkspaceOrganizations3,
  ])

  const [
    selectedWorkAccountForSuggestedExclusions,
    setSelectedWorkAccountForSuggestedExclusions,
  ] = useState()
  const { data: suggestedExclusionsData, refetch: refetchSuggestedExclusions } =
    useQuery(GET_SUGGESTED_EXCLUSIONS_CONTEXT, {
      variables: {
        workspaceId: selectedWorkspace,
        workAccountId: selectedWorkAccountForSuggestedExclusions?.uuid,
      },
      skip: !selectedWorkspace || !selectedWorkAccountForSuggestedExclusions,
    })

  const suggestedExclusions = useMemo(() => {
    try {
      if (
        workAccountWorkspaceConnections?.length > 0 &&
        workAccounts?.length > 0 &&
        !selectedWorkAccountForSuggestedExclusions
      ) {
        for (const connection of workAccountWorkspaceConnections) {
          if (
            (connection?.connectedWorkspaces ?? []).some(
              (workspace) => workspace?.workspaceId === selectedWorkspace
            )
          ) {
            setSelectedWorkAccountForSuggestedExclusions(
              workAccounts.find(
                (workAccount) => workAccount.uuid === connection.workAccountUuid
              )
            )
            break
          }
        }
      }
    } catch (error) {
      logger.warn('Error fetching suggested exclusions')
    }

    return suggestedExclusionsData?.getSuggestedExclusions || []
  }, [
    suggestedExclusionsData,
    workAccountWorkspaceConnections,
    workAccounts,
    selectedWorkspace,
  ])

  const peopleByDomain = useMemo(() => {
    if (!workspacePeopleData?.workspacePeople) return {}
    const people = workspacePeopleData?.workspacePeople.reduce(
      (acc, person) => {
        const domain = extractEmailDomain(person.email)
        acc[domain] = acc[domain] || []
        acc[domain].push(person)
        return acc
      },
      {}
    )
    return people
  }, [workspacePeopleData])

  const peopleByEmail = useMemo(() => {
    if (!workspacePeopleData?.workspacePeople) return {}
    const people = workspacePeopleData?.workspacePeople.reduce(
      (acc, person) => {
        acc[person.email] = person
        return acc
      },
      {}
    )
    return people
  }, [workspacePeopleData])

  const orgsByDomain = useMemo(() => {
    if (!workspaceOrganizationsData?.workspaceOrganizations) return {}
    const orgs = []
    const dataSet = [
      ...(workspaceOrganizationsData?.workspaceOrganizations || []),
      ...(workspaceOrganizationsData2?.workspaceOrganizations || []),
      ...(workspaceOrganizationsData3?.workspaceOrganizations || []),
    ]

    for (const org of dataSet) {
      orgs[org.domain] = mergeObjects(orgs[org.domain] || {}, org)
    }

    for (const domain of Object.keys(orgs)) {
      orgs[domain].people = peopleByDomain?.[domain] || []
    }

    return orgs
  }, [
    workspaceOrganizationsData?.workspaceOrganizations,
    workspaceOrganizationsData2?.workspaceOrganizations,
    workspaceOrganizationsData3?.workspaceOrganizations,
    peopleByDomain,
  ])

  const workspaceOrganizations = useMemo(() => {
    const orgs = Object.values(orgsByDomain)
    return orgs
  }, [orgsByDomain])

  const selectedWorkspaceFromLocalStorage = parseLocalStorageValue(
    localStorage.getItem('selectedWorkspaceV2')
  )

  const defaultWorkspaceId = workspaces[0]?.id

  // The defaultWorkspaceId check ensures we don't try to operate on loading/missing data (i.e. empty array)
  // The !selectedWorkspaceFromLocalStorage check catches the case where no workspace is stored yet
  // The includes() check catches the case where the user previous had access to a workspace and it
  // was stored as their selection, but they no longer have access to it so it's stored in storage but
  // not in the list of available workspaces to choose from.
  if (
    defaultWorkspaceId &&
    (!selectedWorkspaceFromLocalStorage ||
      !workspaces
        ?.map(({ id }) => id)
        ?.includes(selectedWorkspaceFromLocalStorage))
  ) {
    localStorage.setItem('selectedWorkspaceV2', defaultWorkspaceId)
  }

  if (
    !selectedWorkspace &&
    defaultWorkspaceId &&
    workspaces &&
    workspaces.length > 0
  ) {
    setSelectedWorkspace(
      selectedWorkspaceFromLocalStorage &&
        workspaces
          .map(({ id }) => id)
          .includes(selectedWorkspaceFromLocalStorage)
        ? selectedWorkspaceFromLocalStorage
        : defaultWorkspaceId
    )
    setSelectedWorkspaceLoading(false)
  }

  /*
  TODO: discuss if we want this fallback or not

  if (!selectedWorkspace && selectedWorkspaceFromLocalStorage)
    setSelectedWorkspace(selectedWorkspaceFromLocalStorage)
  */

  useEffect(() => {
    if (selectedWorkspace) {
      localStorage.setItem('selectedWorkspaceV2', selectedWorkspace)
    }
  }, [selectedWorkspace])

  // TODO: discuss if we want to CLEAR the selected workspace from localStorage if the user no longer has access to it

  const {
    data: workAccountWorkspaceConnectionsData,
    refetch: refetchWorkAccountWorkspaceConnections,
    loading: workAccountWorkspaceConnectionsLoading,
  } = useQuery<GlobalWorkAccountWorkspaceConnectionsQuery>(
    WORK_ACCOUNT_WORKSPACE_CONNECTIONS_QUERY,
    {
      onCompleted: (data) => {
        setWorkAccountWorkspaceConnections(data.workAccountWorkspaceConnections)
      },
    }
  )

  const internalDomains = useMemo(() => {
    if (
      !workspaces ||
      !workAccounts ||
      workspaces.length === 0 ||
      workAccounts.length === 0 ||
      !Array.isArray(workspaces) ||
      !Array.isArray(workAccounts)
    )
      return []
    const internal = new Set(
      workspaces
        ?.map((workspace) => {
          if (!workspace?.domains || !Array.isArray(workspace.domains))
            return []
          return workspace.domains.map((domain) => domain.domain)
        })
        ?.flat()
    )
    for (const domain of workAccounts.map((account) =>
      extractEmailDomain(account.email)
    )) {
      internal.add(domain)
    }
    const uniqueInternalDomains = Array.from(internal).filter(Boolean)
    return uniqueInternalDomains
  }, [workspaces, workAccounts])

  useEffect(() => {
    if (
      userCoreContact &&
      workspacesData &&
      workAccountsData &&
      selectedWorkspace &&
      workAccountWorkspaceConnectionsData &&
      !(
        workAccountWorkspaceConnectionsLoading ||
        userCoreContactLoading ||
        workAccountsLoading ||
        workspacesLoading
      )
    ) {
      const targetWorkspace = workspacesData.workspaces.find(
        (workspace) => workspace.id === selectedWorkspace
      )

      const { isComplete } = onboardingStatus({
        coreContact: userCoreContact,
        workAccounts: workAccountsData.workAccounts,
        workspace: targetWorkspace,
        workAccountWorkspaceConnections:
          workAccountWorkspaceConnectionsData.workAccountWorkspaceConnections,
      })

      const needsOnboarding = !isComplete

      const excludedRoutesFromForcedOnboarding = ['revokeAlert'] // Add more if necessary

      if (
        needsOnboarding &&
        !excludedRoutesFromForcedOnboarding.includes(route)
      )
        navigate(routes.welcome())
    }
  }, [
    userCoreContact,
    workAccountsData,
    workspacesData,
    selectedWorkspace,
    workAccountWorkspaceConnectionsData,
  ])

  const value = useMemo(() => {
    return {
      userCoreContact,
      setUserCoreContact,
      refetchUserCoreContact,
      workAccounts,
      setWorkAccounts,
      refetchWorkAccounts,
      workspaces,
      setWorkspaces,
      refetchWorkspaces,
      workAccountWorkspaceConnections,
      refetchWorkAccountWorkspaceConnections,
      sidebarObject,
      setSidebarObject,
      selectedWorkspace,
      setSelectedWorkspace,
      people,
      refetchPeople,
      internalDomains,
      suggestedExclusions,
      refetchSuggestedExclusions,
      loading:
        workAccountWorkspaceConnectionsLoading ||
        userCoreContactLoading ||
        workAccountsLoading ||
        workspacesLoading,
      selectedWorkspaceLoading,
      workspacePeople: workspacePeopleData?.workspacePeople || [],
      workspaceOrganizations,
      refetchWorkspaceOrganizations,
      orgsByDomain,
      peopleByEmail,
    }
  }, [
    userCoreContact,
    workAccounts,
    workspaces,
    workAccountWorkspaceConnections,
    sidebarObject,
    selectedWorkspace,
    people,
    refetchPeople,
    internalDomains,
    selectedWorkspaceLoading,
    suggestedExclusions,
    workspacePeopleData,
    workspaceOrganizationsData,
    refetchWorkspaceOrganizations,
    workAccountWorkspaceConnectionsLoading,
    userCoreContactLoading,
    workAccountsLoading,
    workspacesLoading,
    peopleByDomain,
    peopleByEmail,
  ])

  return <DayContext.Provider value={value}>{children}</DayContext.Provider>
}
