import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  Avatar,
  AvatarGroup,
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  lighten,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import {
  DataGridPremium,
  getGridStringOperators,
  GridRenderCellParams,
  GridRowId,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { LicenseInfo } from '@mui/x-license-pro'
import { RiArrowDropDownFill } from '@remixicon/react'
import { Action } from 'types/graphql'

import { DayContext } from 'src/lib/dayContext'

import ActionActions from '../Actions/ActionActions/ActionActions'
import ActionChannelChip from '../Actions/ActionChannelChip/ActionChannelChip'
import ActionDialog from '../Actions/ActionDialog/ActionDialog'
import ActionTypeChip from '../Actions/ActionTypeChip/ActionTypeChip'
import ContactTile from '../ContactTile/ContactTile'
import DomainAvatar from '../DomainAvatar/DomainAvatar'
import OpportunityChip from '../OpportunityChip/OpportunityChip'
import Row from '../Row/Row'
import StageChip from '../StageChip/StageChip'
LicenseInfo.setLicenseKey(process.env.MUI_PREMIUM_LICENSE_KEY)

const groupingColumns = {
  ownerEmail: {
    leafField: 'ownerEmail',
    headerName: 'Owner',
    flex: 0,
    width: 330,
  },
  organization: {
    field: 'organization',
    headerName: 'Org',
    flex: 0,
    width: 330,
  },
  opportunityId: {
    field: 'opportunity',
    headerName: 'Opportunity',
    flex: 0,
    width: 330,
    leafField: 'ownerEmail',
  },
  opportunityIds: {
    field: 'opportunityIds',
    headerName: 'All Opps',
    flex: 0,
    width: 330,
  },
}

const peopleAggregation = {
  // The `apply` method takes the values to aggregate and returns the aggregated value
  apply: (params) => {
    if (params.values.length === 0) {
      return null
    }

    return [...new Set(params.values)].filter(Boolean)
  },
  label: 'people',
  columnTypes: ['string'],
}

const PipelineActions = ({
  actions,
  opportunities,
  pipeline,
  onUpdate,
}: {
  actions: Action[]
  opportunities: any[]
  pipeline: any
  onUpdate: () => void
}) => {
  const { workspacePeople, workspaceOrganizations } = useContext(DayContext)
  const [selectedAction, setSelectedAction] = useState<Action | null>(null)

  const [showHeader, setShowHeader] = useState(false)
  const [type, setType] = useState('MOMENTUM')
  const [grouping, setGrouping] = useState('opportunityId')
  const expansionLookup = useRef<Record<GridRowId, boolean>>({})
  const [columnVisibility, setColumnVisibility] = useState({
    opportunityIds: false,
    organization: false,
    actions: false,
    type: false,
    ownerEmail: false,
    stageId: false,
  })
  const [stagesToShow, setStagesToShow] = useState<string[]>(
    pipeline?.stages?.map((stage) => stage.id)
  )
  const allStagesSelected = useMemo(() => {
    return stagesToShow.length === pipeline?.stages?.length
  }, [stagesToShow, pipeline])

  const selectStageRef = useRef(null)

  const apiRef = useGridApiRef()

  const avatarUrls = useMemo(() => {
    const map = {}
    for (const person of workspacePeople) {
      if (person.photoUrl) {
        map[person.email] = person.photoUrl
      }
    }
    for (const org of workspaceOrganizations) {
      if (org.photos?.square) {
        map[org.domain] = org.photos?.square
      }
    }
    return map
  }, [workspacePeople, workspaceOrganizations])

  const handleSetGrouping = (grouping: string) => {
    setGrouping(grouping)
    const reset = {}
    for (const key of Object.keys(groupingColumns)) {
      reset[key] = false // grouping === key ? false : true
    }
    if (!grouping) {
      reset['opportunityIds'] = true
    }
    setColumnVisibility((prev) => ({
      ...prev,
      ...reset,
    }))
  }

  //const operators = getGridStringOperators()
  //logger.dev({ operators })

  const data = useMemo(() => {
    const oppStagesMap = {}
    for (const stage of pipeline?.stages || []) {
      for (const opp of stage?.opportunities || []) {
        oppStagesMap[opp.id] = stage.id
      }
    }

    return {
      rows:
        actions?.map((action) => {
          const oppId = action.opportunity?.id || action.opportunityIds?.[0]
          const stageId = oppStagesMap[oppId]

          return {
            ...action,
            organization: action?.organizations?.[0]?.domain,
            ownerEmail: action.owner?.email,
            opportunityId: oppId,
            type: action.opportunity?.id ? 'MOMENTUM' : action.type,
            people: action.people.map((person) => person.email).filter(Boolean),
            stageId,
          }
        }) || [],
      columns: [
        {
          field: 'ownerEmail',
          headerName: 'Owner',
          width: 312,
          flex: 0,
          renderCell: (params: GridRenderCellParams) => (
            <Row sx={{ pl: 4 }}>
              <ContactTile
                email={params.value}
                showSidebar={false}
              />
            </Row>
          ),
        },
        {
          field: 'stageId',
          headerName: 'Stage',
          flex: 0,
          width: 100,
          filterOperators: [
            ...getGridStringOperators(),
            {
              value: 'isPresentIn',
              getApplyFilterFn: (filterItem) => {
                if (
                  !filterItem.field ||
                  !filterItem.value ||
                  !filterItem.operator
                ) {
                  return false
                }

                return (params): boolean => {
                  return (
                    params.value &&
                    filterItem.value &&
                    filterItem.value.includes(params.value)
                  )
                }
              },
              InputComponent: null,
            },
          ],
        },
        {
          field: 'people',
          headerName: '',
          flex: 0,
          width: 8 * 14,
          type: 'string',
          renderCell: (params: GridRenderCellParams) => {
            const photoUrls = new Set()

            params.value?.map((email) => {
              photoUrls.add(avatarUrls[email])
            })
            params.row.organizations?.map((org) => {
              photoUrls.add(avatarUrls[org.domain])
            })

            return (
              <AvatarGroup
                max={3}
                sx={{
                  '& .MuiAvatarGroup-avatar': {
                    ml: '-10px',
                    height: '32px',
                    width: '32px',
                    fontSize: '14px',
                    fontWeight: 600,
                    background: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.contrastText,
                  },
                }}
              >
                {[...photoUrls]?.filter(Boolean)?.map((url, index) => (
                  <Avatar
                    key={`action-${index}-${params.id}`}
                    src={url as string}
                    sx={{
                      height: '32px',
                      width: '32px',
                    }}
                  />
                ))}
              </AvatarGroup>
            )
          },
        },
        {
          field: 'title',
          headerName: 'Action',
          flex: 1,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Box
                sx={{ width: '100%', overflow: 'hidden', cursor: 'pointer' }}
                onClick={() => setSelectedAction(params.row)}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '12px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {params.value}
                </Typography>
                <Typography
                  sx={{
                    lineHeight: '160%',
                    fontSize: '11px',
                    color: (theme) =>
                      lighten(theme.palette.text.secondary, 0.2),
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'none',
                  }}
                >
                  {params.row.reasoning}
                </Typography>
              </Box>
            )
          },
        },

        {
          field: 'quickActions',
          headerName: '',
          width: 214,
          flex: 0,
          renderCell: (params: GridRenderCellParams) => {
            const type = params.rowNode.type
            if (type === 'group') {
              return null
            }
            return (
              <ActionActions
                action={params.row}
                onUpdate={onUpdate}
                showButtons={true}
              />
            )
          },
        },
        {
          field: 'opportunityIds',
          headerName: 'All Opps',
          width: 8 * 22,
          flex: 0,
          renderCell: (params: GridRenderCellParams) => {
            const opps = opportunities.filter((opp) =>
              params.row.opportunityIds.includes(opp.id)
            )
            return opps?.length === 1 ? (
              <OpportunityChip
                key={opps?.[0]?.id}
                opportunity={opps?.[0]}
                width={'100%'}
              />
            ) : (
              <Chip
                variant="outlined"
                size="small"
                label={`${opps?.length} Opps`}
                sx={{ width: '100%', justifyContent: 'flex-start' }}
              />
            )
          },
        },
        {
          field: 'opportunityId',
          headerName: 'Opportunity',
          width: 8 * 18,
          flex: 0,
          renderCell: (params: GridRenderCellParams) => {
            const opp = opportunities.find((opp) => opp.id === params.value)

            const type = params.rowNode.type
            return opp ? (
              <OpportunityChip
                opportunity={opp}
                size={type === 'group' ? 'medium' : 'small'}
              />
            ) : null
          },
          leafField: 'ownerEmail',
        },
        {
          field: 'organization',
          headerName: 'Org',
          flex: 0,
          width: 270,
          renderCell: (params: GridRenderCellParams) => {
            const org = workspaceOrganizations.find(
              (org) => org.domain === params.value
            )
            return (
              params.value && (
                <Row gap={1}>
                  <DomainAvatar
                    domain={params.value}
                    size={28}
                  />
                  <Box sx={{ width: '164px', overflow: 'hidden' }}>
                    <Typography
                      variant="h3"
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        fontSize: '16px',
                      }}
                    >
                      {org?.name || params.value}
                    </Typography>
                    <Typography>{org?.aiDescription}</Typography>
                  </Box>
                </Row>
              )
            )
          },
        },
        {
          field: 'channel',
          headerName: 'Channel',
          flex: 0,
          width: 8 * 20,
          renderCell: (params: GridRenderCellParams) => {
            const type = params.rowNode.type
            if (type === 'group') {
              return null
            }
            return (
              <ActionChannelChip
                action={params.row}
                width={'100%'}
              />
            )
          },
        },
        {
          field: 'type',
          headerName: 'Type',
          flex: 0,
          width: 214,
          renderCell: (params: GridRenderCellParams) => {
            return <ActionTypeChip type={params.value} />
          },
          //filterOperators: [...getGridStringOperators()],
        },
      ],
    }
  }, [actions, opportunities, workspaceOrganizations, workspacePeople])

  useEffect(() => {
    apiRef.current.subscribeEvent('rowExpansionChange', (node) => {
      expansionLookup.current[node.id] = node.childrenExpanded
    })
  }, [apiRef])

  const isGroupExpandedByDefault = useCallback(
    (node: GridRowTreeNodeConfig) => {
      return !!expansionLookup.current[node.id]
    },
    [expansionLookup]
  )

  const handleStagesChange = (event: SelectChangeEvent<string[]>) => {
    const allValues = event.target.value as string[]
    const lastValue = allValues?.[allValues.length - 1]
    if (lastValue === 'all') {
      if (allStagesSelected) {
        setStagesToShow([])
      } else {
        setStagesToShow(pipeline?.stages?.map((stage) => stage.id))
      }
    } else {
      const uniqueValues = [...new Set<string>(allValues)].filter(Boolean)
      setStagesToShow(uniqueValues)
    }
  }

  const filterItems = useMemo(() => {
    const typeItems = type
      ? [{ id: 'type', field: 'type', value: type, operator: 'equals' }]
      : []
    const stageItems = [
      {
        id: 'stageId',
        field: 'stageId',
        value: stagesToShow,
        operator: 'isPresentIn',
      },
    ]
    return [...typeItems, ...stageItems]
  }, [type, stagesToShow])

  return (
    <>
      <Row
        sx={{
          justifyContent: 'space-between',
          px: 3,
        }}
      >
        <Row
          gap={2}
          sx={{
            height: '64px',
            alignItems: 'center',
            '& .MuiTabs-root': {
              width: '100%',
              borderRadius: '4px',
              border: (theme) => `1px solid ${theme.palette.divider}`,
              background: (theme) => theme.palette.background.paper,
              '& .MuiTab-root': {
                border: 'none',
                borderRadius: '4px',
                flexGrow: 1,
                py: '4px',
                px: '12px',
                textTransform: 'none',
                fontSize: '12px',
                fontWeight: 600,
                minHeight: '0px',
                height: '30px',
                fontFeatureSettings: 'liga off, clig off',
                m: '4px',
                '&.Mui-selected': {
                  background: (theme) => theme.palette.action.selected,
                },
              },
            },
          }}
        >
          <Box
            sx={{
              width: '400px',
            }}
          >
            <Tabs
              value={type}
              onChange={(event, value) => setType(value)}
              TabIndicatorProps={{
                sx: {
                  height: '0px',
                },
              }}
            >
              <Tab
                value={null}
                label="All"
              />
              <Tab
                value="SUPPORT"
                label="Support"
              />
              <Tab
                value="FOLLOWUP"
                label="Follow Up"
              />
              <Tab
                value="MOMENTUM"
                label="Momentum"
              />
            </Tabs>
          </Box>
          <Box sx={{ width: '400px' }}>
            <Tabs
              value={grouping}
              onChange={(event, value) => handleSetGrouping(value)}
              TabIndicatorProps={{
                sx: {
                  height: '0px',
                },
              }}
            >
              <Tab
                value={null}
                label="All"
              />
              <Tab
                value="ownerEmail"
                label="Owner"
              />
              <Tab
                value="organization"
                label="Org"
              />
              <Tab
                value="opportunityId"
                label="Opp"
              />
            </Tabs>
          </Box>
        </Row>
        <Row>
          <ToggleButtonGroup
            exclusive={true}
            value={showHeader}
            onChange={(event, value) => setShowHeader(value)}
            sx={{
              display:
                false && process.env.HOST.includes('localhost')
                  ? 'flex'
                  : 'none',
              height: '40px',
              '& .MuiToggleButton-root': {
                fontSize: '12px',
                fontWeight: 600,
                letterSpacing: '-0.2px',
                textTransform: 'none',
              },
            }}
          >
            <ToggleButton value={true}>Headers</ToggleButton>
            <ToggleButton value={false}>Compact</ToggleButton>
          </ToggleButtonGroup>

          <FormControl
            sx={{
              width: '232px',
              borderRadius: '4px',
              height: '40px',
              p: 0,
              border: 'none',
              '& .MuiSelect-select': {
                p: 0,
                borderRadius: '4px',
                height: '40px',
                alignItems: 'center',
                width: '232px',
                border: 'none',
                background: (theme) => theme.palette.background.paper,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: (theme) => `1px solid ${theme.palette.divider}`,
                width: '232px',
              },
              '& .MuiInputLabel-shrink': {
                display: 'none',
              },
            }}
          >
            <InputLabel
              sx={{
                top: '-16px',
                left: '-12px',
                maxWidth: '192px',
                width: '192px',
                p: 0,
                m: 0,
                justifyContent: 'left',
              }}
            >
              <Chip
                label="No stages selected"
                size="small"
                variant="filled"
                clickable={true}
                color="warning"
                sx={{
                  width: '184px',
                  ml: '5px',
                  my: '5px',
                  height: '30px',
                  border: 'none',
                  borderRadius: '4px',
                  justifyContent: 'left',
                  '& .MuiChip-label': {
                    fontSize: '12px',
                    fontWeight: 600,
                    letterSpacing: '-0.2px',
                  },
                }}
              />
            </InputLabel>
            <Select
              multiple={true}
              value={stagesToShow}
              onChange={handleStagesChange}
              sx={{}}
              IconComponent={(props) => {
                return (
                  <RiArrowDropDownFill
                    style={{
                      height: '36px',
                      width: '36px',
                      flexShrink: 0,
                      background: 'white',
                      m: 0,
                      p: 0,
                    }}
                  />
                )
              }}
              renderValue={(selected) => {
                if (allStagesSelected) {
                  return (
                    <Chip
                      label="All stages"
                      variant="outlined"
                      sx={{
                        width: '184px',
                        ml: '5px',
                        my: '5px',
                        height: '30px',
                        border: 'none',
                        background: (theme) => theme.palette.action.selected,
                        borderRadius: '4px',
                        justifyContent: 'left',
                        '& .MuiChip-label': {
                          fontSize: '12px',
                          fontWeight: 600,
                          letterSpacing: '-0.2px',
                        },
                      }}
                    />
                  )
                } else if (selected.length === 0) {
                  return 'No stages selected'
                } else if (selected.length === 1) {
                  const stage = pipeline?.stages?.find(
                    (s) => s.id === selected[0]
                  )
                  return (
                    <Box
                      sx={{
                        height: '30px',
                        ml: '5px',
                        my: '5px',
                        width: '184px',
                      }}
                    >
                      <StageChip
                        stage={stage}
                        height={'30px'}
                      />
                    </Box>
                  )
                } else {
                  return (
                    <Chip
                      label={`${selected.length} Stages`}
                      variant="outlined"
                      sx={{
                        width: '184px',
                        ml: '5px',
                        my: '5px',
                        height: '30px',
                        border: 'none',
                        background: (theme) => theme.palette.action.selected,
                        borderRadius: '4px',
                        justifyContent: 'left',
                        '& .MuiChip-label': {
                          fontSize: '12px',
                          fontWeight: 600,
                          letterSpacing: '-0.2px',
                        },
                      }}
                    />
                  )
                }
              }}
              ref={selectStageRef}
              placeholder="Select stages"
            >
              <MenuItem
                key={`stage-select-all`}
                value={'all'}
              >
                <Checkbox checked={allStagesSelected} />
                <ListItemText primary="All" />
              </MenuItem>
              {pipeline?.stages?.map((stage) => (
                <MenuItem
                  key={`stage-select-${stage.id}`}
                  value={stage.id}
                >
                  <Checkbox checked={stagesToShow.includes(stage.id)} />
                  <ListItemText primary={stage.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Row>
      </Row>
      <Box
        sx={{
          height: 'calc(100vh - 128px - 64px)',
          background: (theme) => theme.palette.background.paper,
          borderRadius: '12px',
          border: '1px solid',
          borderColor: (theme) => theme.palette.divider,
          mx: 3,
        }}
      >
        <DataGridPremium
          apiRef={apiRef}
          rows={data.rows}
          columns={data.columns}
          autoHeight={false}
          disableRowSelectionOnClick={true}
          loading={!actions || actions.length === 0}
          filterModel={{
            items: filterItems,
          }}
          rowGroupingModel={grouping ? [grouping] : []}
          groupingColDef={grouping ? groupingColumns[grouping] : null}
          isGroupExpandedByDefault={isGroupExpandedByDefault}
          aggregationFunctions={{ peopleCombined: peopleAggregation }}
          aggregationModel={{
            people: 'peopleCombined',
          }}
          getAggregationPosition={(groupNode) => 'inline'}
          columnVisibilityModel={columnVisibility}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              display: showHeader ? 'block' : 'none',
            },
            '& .MuiDataGrid-groupingCriteriaCellToggle': {
              mr: '4px',
              ml: '-4px',
              '& .MuiIconButton-root': {
                p: '2px',
                borderRadius: '3px',
              },
              '& .MuiSvgIcon-root': {
                height: '24px',
                width: '24px',
              },
            },
          }}
        />
      </Box>
      <ActionDialog
        action={selectedAction}
        opportunities={
          selectedAction
            ? opportunities.filter((opp) =>
                selectedAction.opportunityIds.includes(opp.id)
              )
            : []
        }
        onUpdate={onUpdate}
        onClose={() => setSelectedAction(null)}
      />
    </>
  )
}

export default PipelineActions
